import {Injectable} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Client, User, Address, Unread, ThreadMessage, Thread, loadObject} from '@nxt/model-core';

import {FireService} from './fire.service';
import {ClientService} from '../../shared/_services/client.service';
import {LocalStorageService} from '../../shared/_services/local-storage.service';
import {UserService} from './user.service';

import {MessagingService} from './messaging.service';

import {PageService} from '../../shared/_services/page.service';
import {Router} from '@angular/router';

@Injectable()
export class AccountService {
    uBR: any;
    uSub: Subscription;

    constructor(
        private cSvc: ClientService,
        private uSvc: UserService,
        private mSvc: MessagingService,
        private fSvc: FireService,
        private pSvc: PageService,
        private lSvc: LocalStorageService,
        public auth: AngularFireAuth
    ) {

        this.cSvc.signedOut = this.auth.authState.pipe(filter(u => !u));

        combineLatest([this.uSvc.user$, this.cSvc.clients$, this.cSvc.c$])
            .pipe(map(res => {
                return {user: res[0], clients: res[1], c: res[2]};
            }))
            .subscribe(
                async (result) => {

                    if (result.user) {
                        if (result.clients
                            && result.c
                            && (!this.cSvc.client$.getValue() || result.c.id !== this.cSvc.client$.getValue().id)
                        ) {

                            let client: Client = result.clients.find(item => item.id === result.c.id);
                            if (!client) {
                                client = result.clients[0];
                            }
                            await this.setClient(client);

                            // Make sure active thread doesn't stay alive when jumping between clients.
                            this.mSvc.thread$.next(null);
                            this.uBR = null;

                            // let devices = await this.fSvc.getColl(`users/${result.user.id}/devices`).toPromise();
                            // Load the user's location with respect to the current client.
                            let doc = await this.fSvc.getDoc(`users/${result.user.id}/locations/${this.cSvc.client_id}`).toPromise();
                            if (doc?.exists && doc?.data()?.ref) {
                                let a: Address = new Address(await doc.data().ref.get())
                                this.uSvc.location$.next(a);
                            } else {
                                this.uSvc.location$.next(null);
                            }
                            // load user signature
                            doc = await this.fSvc.getDoc(`users/${result.user.id}/signatures/${this.cSvc.client_id}`).toPromise();
                            if (doc?.exists && doc?.data()?.signature) {
                                this.uSvc.signature$.next(doc?.data()?.signature);
                            } else {
                                this.uSvc.signature$.next('');
                            }
                        }
                    } else if (result.user !== undefined) {
                        await this.setClient(null);
                        this.uBR = null;
                        await this.lSvc.flushState();
                    }
                }
            );

        this.auth.user.subscribe(
            async (user: any) => {
                if (user) {
                    return this.loadClients(user);
                } else {
                    this.cSvc.u$.next(null);
                    this.uSvc.user$.next(null);
                    await this.setClient(null);
                    this.cSvc.clients$.next([]);
                }
            },
            async (err: any) => {
                console.warn(err);
                await this.signOut();
            }
        );

    }

    async loadClients(user) {
        this.pSvc.blocking$.next(true);
        try {
            user = user?.multiFactor?.user || user;
            let u: User =  ( await this.fSvc.getObject(`users/${user.uid}`) ) as User;
            if (u?._exists) {
                let result = await this.cSvc.callAPI(`/cms/user/clients`, 'get', null, await user.getIdToken());
                if (result?.clients?.length) {
                    this.cSvc.clients$.next(result.clients.map(c => {
                        c = new Client(c);
                        c._docRef = u._docRef.firestore.doc(`clients/${c.id}`);
                        return c;
                    }) || []);
                    let client = (result.clients.find(i => i.id === this.cSvc.client_id));
                    if (!client) {
                        client = result.clients[0];
                    }
                }
                if (result?.terms) {
                    this.cSvc.terms$.next(result.terms);
                }
                if (user?.uid !== this.cSvc.u$.getValue()?.uid) {
                    this.cSvc.u$.next(user);
                }
                if (result.clients?.length) {
                    this.uSvc.user$.next(u);
                    this.watchUser(u);
                    this.watchUnreads(u);
                } else {
                    this.uSvc.user$.next(null);
                }
            }
        } catch (e) {
            console.log(e?.error);
            if (e?.error?.title === 'Permission Denied') {
                await this.signOut();
            } else {
                console.warn(e, 'user');
            }
        }
        this.pSvc.blocking$.next(false);
    }

    async watchUser(u) {
        this.fSvc.watchObject(`users/${u.id}`, null, true)
            .subscribe(
                ([obj,delta]) => {
                    this.mSvc.counts$.next(obj['unread']);
                },
                e => {
                    console.warn(e, 'user2');
                }
            );
    }

    async watchUnreads(u) {
        this.cSvc.client$.subscribe(async (client) => {
            if (client?.id) {
                // console.log(`users/${u.id}/clients/${client.id}/unread`);
                this.uSub?.unsubscribe();
                this.uSub = this.fSvc.watchColl(`users/${u.id}/clients/${client.id}/unread`, [{name:'limit',args:[200]}])
                    .subscribe(async res => {
                        console.log('res.length', res?.length);
                        this.uSvc.unreads$.next(await Promise.all(res.map(async r => {
                            let u: Unread = new Unread(r)
                            let obj: ThreadMessage | Thread = loadObject(await u.ref.get(), {olm: this.fSvc.olm});
                            if (obj?._exists) {
                                obj._unread = u;
                            }
                            return obj;
                        })));
                    });
            }

        })
    }

    async loadImages(client: Client) {
        this.uSvc.images = this.uSvc.images || {};
        this.uSvc.images[client.id] = {};
    }

    async signOut() {
        await this.lSvc.flushState();
        await this.auth.signOut();
    }

    async getUsersByRole(): Promise<any> {
        if (!this.uBR && this.cSvc.client$.getValue()) {
            this.uBR = await this.cSvc.callAPI(`/cms/users_by_role`, 'get');
            if (
                this.uSvc.isRole(['restricted'])
                && !this.uSvc.isRole(['admin'])
            ) {
                Object.keys(this.uBR).map(key => {
                    this.uBR[key] = this.uBR[key]?.reduce((users,u) => {
                        if (!u._restricted || u.id === this.uSvc.user$.getValue().id) {
                            users.push(u);
                        }
                        return users;
                    }, []);
                });
            }
            this.uBR.agents = this.cSvc.client$.getValue()?.agents;
        }
        return this.uBR;
    }

    async setClient(client: Client) {
        // Load users claims for this client
        if (client?.id) {
            let roles: any = await this.cSvc.callAPI(`/cms/user/roles`, 'post', {client_id: client.id});
            this.uSvc.roles$.next(roles || {});
        } else {
            this.uSvc.roles$.next(null);
        }
        this.mSvc.thread$.next(null);
        this.cSvc.client$.next(client);
    }
}
