import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {isValidEmail} from '@nxt/model-core';
import {NgIf} from '@angular/common';

@Component({
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule
    ],
    selector: 'input-stacked-text',
    template: `
        <div *ngIf="form && controlName" [formGroup]="form"
             [class]="maxWidth + 'w-full relative mt-4 bg-white border border-gray-300 rounded-md shadow-sm ' + class">
            <label *ngIf="label" [for]="controlName"
                   class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white rounded-md text-xs font-medium text-gray-900">{{label}}</label>
            <div class="flex px-3 py-2">
                <input [type]="inputType"
                       [id]="controlName"
                       [autocomplete]="autoComplete ? 'on' : 'off'"
                       (keyup.enter)="onEnter.emit()"
                       (keydown.tab)="onTab.emit()"
                       (change)="onChange.emit()"
                       (keyup)="onKeyup.emit($event)"
                       (keyup.escape)="onEscape.emit()"
                       (blur)="onBlur.emit(form.get(controlName).value)"
                       [readonly]="readOnly"
                       (search)="onClear.emit()"
                       [placeholder]="placeholder||''"
                       [formControlName]="controlName"
                       [class]="form?.get(controlName)?.touched && form.get(controlName)?.hasError('required') ? errorInputClass : inputClass"
                />
                <a [href]="form?.get(controlName)?.value" target="_blank" class="underline ml-2 cursor-pointer text-xs italic" *ngIf="inputType==='url'">View</a>
            </div>
            <p *ngIf="!hideErrors && form?.get(controlName)?.touched && form.get(controlName)?.hasError('required')"
               class="px-3 py-1 italic text-xs text-red-500">
                Required
            </p>
            <p *ngIf="!hideErrors && form?.get(controlName)?.touched && form.get(controlName)?.hasError('emailOrPhoneRequired')"
               class="px-3 py-1 italic text-xs text-red-500">
                Email or phone is required.
            </p>
            <p *ngIf="!hideErrors && form?.get(controlName)?.touched && form.get(controlName)?.hasError('email')"
               class="px-3 py-1 italic text-xs text-red-500">
                The email format is invalid
            </p>
            <p *ngIf="!hideErrors && form?.get(controlName)?.touched && form.get(controlName)?.hasError('invalidEmail')"
               class="px-3 py-1 italic text-xs text-red-500">
                The email format is invalid
            </p>
            <p *ngIf="!hideErrors && form?.get(controlName)?.touched && form.get(controlName)?.hasError('noMatch')"
               class="px-3 py-1 italic text-xs text-red-500">
                Passwords must match.
            </p>
            <p *ngIf="!hideErrors && form?.get(controlName)?.touched && form.get(controlName)?.hasError('minLength')"
               class="px-3 py-1 italic text-xs text-red-500">
                Value is too short.
            </p>
        </div>
    `
})
export class InputStackedTextComponent implements OnInit {
    @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onTab: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEscape: EventEmitter<any> = new EventEmitter<any>();
    @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() inputType: 'text'|'email'|'number'|'password'|'date'|'time'|'url'|string = 'text';
    @Input() readOnly: boolean = false;
    @Input() class: string = '';
    @Input() inputOverrideClass: string = '';
    @Input() hideErrors: boolean = false;
    @Input() default:string;
    @Input() autoComplete?: boolean = false;

    get maxWidth(): string {
        return (this.inputType==='number') ? ' max-w-400 ' : ' max-w-500 '
    }

    // Dynamic styles for the input field.
    baseInputClass: string = 'w-full block border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-xs';

    get errorInputClass() {
        return `input-error ${this.inputOverrideClass||this.baseInputClass}`;
    }

    get inputClass() {
        return `input-default ${this.inputOverrideClass||this.baseInputClass}`;
    }

    ngOnInit() {
        ['form', 'controlName'].forEach(prop => {
            if (!this[prop]) {
                console.warn(`Cannot render text input. Missing value for ${prop}`);
            }
        });
        if (!this.form?.get(this.controlName)?.value && this.default) {
            this.form.get(this.controlName).setValue(this.default);
        }
    }
}

export function emailValidator(control: AbstractControl) {
    if (control.value) {
        return isValidEmail(control.value) ? null : {'invalidEmail': true};
    } else {
        return null;
    }
}
