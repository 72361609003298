import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

import {ClientService} from '@library/shared/_services/client.service';
import {PageService} from '@library/shared/_services/page.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {IconsComponent} from '@library/shared/icons/icons.component';

import {Page} from '@nxt/model-core';
import {CartService} from '@library/shared/_services/cart.service';
import {LaroConsumerUserDropdown} from './laro-consumer-user-dropdown';

@Component({
    selector: 'laro-consumer-header',
    standalone: true,
    imports: [
        CommonModule, RouterModule, IconsComponent, LaroConsumerUserDropdown
    ],
    template: `
    <div class="relative isolate z-50 shadow sticky top-0">
        <div class="bg-[#33ccd6] p-4 pt-8">
            <div class="mx-auto max-w-7xl flex justify-between">
                <a routerLink="/" class="btn-clear p-0 pl-2">
                    <img src="/assets/logos/horizontal/laro-white.png" class="h-8">
                </a>
                <div class="text-white pt-3 text-sm uppercase tracking-widest Oswald place-items-center">
                    <a [routerLink]="item.routerLink" class="hidden md:inline mr-4" *ngFor="let item of section?.items">{{ item.label }}</a>
                </div>
                <div class="flex space-x-1 place-items-center">
                    <a class="md:ml-3 relative" routerLink="/checkout" *ngIf="(cartSvc.cartDocument$|async)?.checkout?.items?.length">
                        <div style="height: 10px; width: 10px; line-height:20px; border-radius: 50%;"
                             class="flex place-content-center bg-red-600 rounded-full text-xs text-white absolute -top-1 -left-1">
                            &nbsp;
                        </div>
                        <icon name="heroicon-outline-shopping-cart" class="text-white h-8 w-8"></icon>
                    </a>
                    <laro-consumer-user-dropdown [u]="cSvc.u$|async" [user]="cSvc.user"></laro-consumer-user-dropdown>
                </div>
            </div>
        </div>
        
        <div *ngIf="show$|async" class="{{ menuState }} absolute inset-x-0 top-0 -z-10 bg-accent pt-20 shadow-lg ring-1 ring-gray-900/5">
            <div class="p-1 pl-8 w-full flex place-content-start">
                <button class="btn-clear btn-xs hover:text-white" (click)="hide()">
                    <icon name="heroicon-outline-x" class="h-6 w-6"></icon>
                </button>
            </div>
            <div class="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 px-6 pb-10 lg:grid-cols-2 lg:px-8">
                <div class="grid grid-cols-2 gap-x-6 sm:gap-x-8">
                    <div>
                        <div class="mt-6 flow-root">
                            <div class="-my-2">
                                <a *ngFor="let option of section.items"
                                   [routerLink]="option.routerLink"
                                   (click)="hide()"
                                   [title]="option.label"
                                   class="flex place-items-center gap-x-4 py-2 text-sm font-semibold text-gray-900 hover:text-white"
                                >
                                    <span>{{option.label}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div class="grid grid-cols-1 gap-10 sm:gap-8 lg:grid-cols-2">-->
<!--                    <h3 class="sr-only">Recent posts</h3>-->
<!--                    <article class="relative isolate flex max-w-2xl flex-col gap-x-8 gap-y-6 sm:flex-row sm:items-start lg:flex-col lg:items-stretch">-->
<!--                        <div class="relative flex-none">-->
<!--                            <img class="aspect-[2/1] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[16/9] sm:h-32 lg:h-auto" src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80" alt="">-->
<!--                            <div class="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10"></div>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <div class="flex items-center gap-x-4">-->
<!--                                <time datetime="2023-03-16" class="text-sm leading-6 text-gray-600">Mar 16, 2023</time>-->
<!--                                <a href="#" class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-xs font-medium text-gray-600 hover:bg-gray-100">Marketing</a>-->
<!--                            </div>-->
<!--                            <h4 class="mt-2 text-sm font-semibold leading-6 text-gray-900">-->
<!--                                <a href="#">-->
<!--                                    <span class="absolute inset-0"></span>-->
<!--                                    Boost your conversion rate-->
<!--                                </a>-->
<!--                            </h4>-->
<!--                            <p class="mt-2 text-sm leading-6 text-gray-600">Et et dolore officia quis nostrud esse aute cillum irure do esse. Eiusmod ad deserunt cupidatat est magna Lorem.</p>-->
<!--                        </div>-->
<!--                    </article>-->
<!--                    <article class="relative isolate flex max-w-2xl flex-col gap-x-8 gap-y-6 sm:flex-row sm:items-start lg:flex-col lg:items-stretch">-->
<!--                        <div class="relative flex-none">-->
<!--                            <img class="aspect-[2/1] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[16/9] sm:h-32 lg:h-auto" src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80" alt="">-->
<!--                            <div class="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10"></div>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <div class="flex items-center gap-x-4">-->
<!--                                <time datetime="2023-03-10" class="text-sm leading-6 text-gray-600">Mar 10, 2023</time>-->
<!--                                <a href="#" class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-xs font-medium text-gray-600 hover:bg-gray-100">Sales</a>-->
<!--                            </div>-->
<!--                            <h4 class="mt-2 text-sm font-semibold leading-6 text-gray-900">-->
<!--                                <a href="#">-->
<!--                                    <span class="absolute inset-0"></span>-->
<!--                                    How to use search engine optimization to drive sales-->
<!--                                </a>-->
<!--                            </h4>-->
<!--                            <p class="mt-2 text-sm leading-6 text-gray-600">Optio cum necessitatibus dolor voluptatum provident commodi et.</p>-->
<!--                        </div>-->
<!--                    </article>-->
<!--                </div>-->
            </div>
        </div>
    </div>

    `
})
export class LaroConsumerHeader extends OnDestroyPage {
    @Input() page: Page;
    @Input() section: any;
    show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    path: string = null;
    label: string;
    menuState: string = 'opacity-0 -translate-y-1';
    show: boolean = false;

    constructor(
        public cSvc: ClientService,
        public pSvc: PageService,
        public cartSvc: CartService
    ) {
        super();
    }

    hide() {
        this.show = false;
        this.showHideMenu();
    }

    showHideMenu() {
        switch (this.show) {
            case true:
                this.show$.next(true);
                setTimeout(() => {
                    this.menuState = 'opacity-100 translate-y-0 transition ease-out duration-200';
                }, 200);
                break;
            default:
                this.menuState = 'opacity-0 -translate-y-1 transition ease-in duration-150';
                setTimeout(() => {
                    this.show$.next(false);
                }, 200);
                break;
        }
    }
}
