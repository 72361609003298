"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceItem = exports.EPriceLogicVerbs = exports.EDiscountLogicSubjects = exports.EPriceLogicSubjects = exports.EDenominations = exports.EPriceItemTypes = exports.PriceItemTypes = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
exports.PriceItemTypes = {
    qty: { label: 'Quantity', description: 'Pricing rate is based on an integer quantity (e.g. number of overnights)' },
    blocktime: {
        label: 'Block Time',
        description: 'Time-based rate using block time as multiplier.',
        discounting_description: 'Override regular block time based rate.'
    },
    flighttime: {
        label: 'Flight Time',
        description: 'Time-based rate using actual flight time as multiplier.',
        discounting_description: 'Override regular flight time based rate.'
    },
    waittime: {
        label: 'Wait Time',
        description: 'For same day flights, time between landing and departure.',
        discounting_description: 'Override regular with time based rate.'
    },
    repo: {
        label: 'Reposition',
        description: 'Fees charged for repositioning aircraft based on departure or arrival airport.'
    },
    percent: { label: 'Percent', description: 'Percent-based rate, applied to the base cost of the flight (e.g. FET)' },
    flat: { label: 'Flat', description: 'Flat rate fee, not based on any quantities or flight time values.' },
    decline: {
        label: 'Decline',
        description: 'Rule that, if met, will not quote the flight (e.g. certain international flights).',
        discounting_description: 'This type of rule will hide the flight from the public.'
    }
};
var EPriceItemTypes;
(function (EPriceItemTypes) {
    EPriceItemTypes["QUANTITY"] = "qty";
    EPriceItemTypes["BLOCK_TIME"] = "blocktime";
    EPriceItemTypes["FLIGHT_TIME"] = "flighttime";
    EPriceItemTypes["WAIT_TIME"] = "waittime";
    EPriceItemTypes["REPOSITION"] = "repo";
    EPriceItemTypes["PERCENT"] = "percent";
    EPriceItemTypes["FLAT"] = "flat";
    EPriceItemTypes["DECLINE"] = "decline";
    EPriceItemTypes["NONE"] = "";
})(EPriceItemTypes || (exports.EPriceItemTypes = EPriceItemTypes = {}));
var EDenominations;
(function (EDenominations) {
    EDenominations["HOUR"] = "hr";
    EDenominations["NIGHT"] = "night";
    EDenominations["PASSENGER"] = "pax";
    EDenominations["PASSENGER_LEG"] = "pax_leg";
    EDenominations["STOP"] = "stop";
    EDenominations["NOT_APPLICABLE"] = "";
})(EDenominations || (exports.EDenominations = EDenominations = {}));
var EPriceLogicSubjects;
(function (EPriceLogicSubjects) {
    EPriceLogicSubjects["DEPARTURE_AIRPORT"] = "Departure Airport";
    EPriceLogicSubjects["ARRIVAL_AIRPORT"] = "Arrival Airport";
    EPriceLogicSubjects["REPOSITION_AIRPORT"] = "Reposition Airport";
    // ARRIVAL_COUNTRY = 'Arrival Country',
    // DEPARTURE_COUNTRY = "Departure Country",
    EPriceLogicSubjects["ANY_COUNTRY"] = "Country";
    EPriceLogicSubjects["STOPS"] = "Stops";
    EPriceLogicSubjects["AIRCRAFT"] = "Aircraft";
    EPriceLogicSubjects["NIGHTS"] = "Number of Nights";
    EPriceLogicSubjects["TRIP_TYPE"] = "Trip Type";
    EPriceLogicSubjects["REPOSITION_TIME"] = "Reposition Block Time";
    EPriceLogicSubjects["FLIGHT_TIME"] = "Flight Time";
    EPriceLogicSubjects["WAIT_TIME"] = "Wait Time";
    // EMPTY_LEG = 'Empty Leg'
})(EPriceLogicSubjects || (exports.EPriceLogicSubjects = EPriceLogicSubjects = {}));
var EDiscountLogicSubjects;
(function (EDiscountLogicSubjects) {
    EDiscountLogicSubjects["DAYS_UNTIL_DEPARTURE"] = "Days Until Departure";
    EDiscountLogicSubjects["FLIGHT_TIME"] = "Flight Time";
    EDiscountLogicSubjects["AIRCRAFT"] = "Aircraft";
})(EDiscountLogicSubjects || (exports.EDiscountLogicSubjects = EDiscountLogicSubjects = {}));
var EPriceLogicVerbs;
(function (EPriceLogicVerbs) {
    EPriceLogicVerbs["IS"] = "is";
    EPriceLogicVerbs["IS_IN"] = "is in";
    EPriceLogicVerbs["IS_NOT"] = "is not";
    EPriceLogicVerbs["IS_LESS_THAN"] = "is less than";
    EPriceLogicVerbs["IS_GREATER_THAN"] = "is greater than";
})(EPriceLogicVerbs || (exports.EPriceLogicVerbs = EPriceLogicVerbs = {}));
class PriceItem extends model_core_1.Base {
    constructor(data, olMap, type, idLen) {
        var _a;
        super(type || 'pricing', idLen || 9);
        this.label = '';
        this.public_label = '';
        this.description = '';
        this.emptyOnly = false;
        this.taxable = null;
        this.tax = null;
        this.type = EPriceItemTypes.NONE;
        this.quantity = null;
        this.minQty = null;
        this.dailyMin = null;
        this.multiplier = null;
        this.amount = null;
        this.editedBy = '';
        this.order = null;
        this.repositionAirport = null;
        this.repositionDays = null; // number of days between legs, beyond which treat each airport as repo-worthy.
        this.denomination = EDenominations.NOT_APPLICABLE;
        this.notes = '';
        this.logic = [];
        this._m = ['label', 'description', 'taxable', 'tax', 'type', 'quantity', 'minQty', 'dailyMin', 'amount', 'order', 'repositionAirport', 'repositionDays', 'denomination', 'logic', 'multiplier'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, PriceItem);
        (_a = this === null || this === void 0 ? void 0 : this.logic) === null || _a === void 0 ? void 0 : _a.forEach((item, i) => {
            if (this.logic[i]['operator'] && !this.logic[i].verb) {
                this.logic[i].verb = this.logic[i]['operator'];
            }
        });
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        ['repositionAirport'].forEach(prop => { var _a; return r[prop] = ((_a = this[prop]) === null || _a === void 0 ? void 0 : _a.toJSON) ? this[prop].toJSON() : this[prop]; });
        (_a = r.logic) === null || _a === void 0 ? void 0 : _a.forEach((item, i) => {
            delete r.logic[i]['operator'];
        });
        if (r.minQty) {
            r.minQty = isNaN(Number(r.minQty)) ? null : Number(r.minQty);
        }
        return r;
    }
}
exports.PriceItem = PriceItem;
index_1.olm.pricing = (ref, map) => {
    return new PriceItem(ref, map);
};
