import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {Deal, ERequestSource, ETripType, getScoreColor, QuoteRequest, TripLeg} from '@nxt/model-laro';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {FlightInfo} from './flight-info';
import {Client} from '@nxt/model-core';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {LaroMarketplacePooledQuoteForm} from './laro-marketplace-pooled-quote-form';

@Component({
    standalone: true,
    imports: [
        CommonModule, IconsComponent, FlightInfo,
        PopButtonComponent,
        LaroMarketplacePooledQuoteForm
    ],
    selector: 'laro-consumer-marketplace-book-new-flight',
    template: `
        <div class="bg-black text-white w-full">

            <div class="max-w-4xl m-auto pt-6">
                <div class="flex flex-col p-5">
                    <div class="text-xl font-bold uppercase">Book a New Flight</div>
                    <div class="italic text-sm">Enter your trip details below and get an instant price estimate.</div>
                </div>
            </div>

            <div class="bg-[#3a87ae] text-white w-full p-6 md:p-10 lg:p-12">
                
                <div class="max-w-4xl m-auto pt-6">
                    <laro-marketplace-pooled-quote-form
                            [quote]="quote"
                            (onResults)="loadResults($event)"
                    ></laro-marketplace-pooled-quote-form>
                </div>
                
                <div class="w-full p-5" *ngIf="clientQuotes?.length">
                    
                    <ng-container *ngFor="let client of clientQuotes">
                        <ng-container *ngFor="let result of client.quotes">
                            <ng-container *ngIf="!result.estimate?.error">

                                <div class="grid grid-cols-7 mb-2 p-2 rounded-md bg-[#69a4c1] hover:bg-gray-400 cursor-pointer"
                                     (click)="setQuote(result,client)"
                                >
                                    <div class="col-span-7 md:col-span-1 flex flex-col place-items-center place-content-center">
                                        <img [src]="client?.theme?.logo_light" class="h-4">
                                        <span class="text-xs mt-2 italic">{{ result?.aircraft?.name }}</span>
                                    </div>
                                    <div class="col-span-3 md:col-span-1">
                                        <div class="w-full h-full text-center md:text-left p-3">
                                            <div class="text-sm italic">
                                                {{ result.legs[0].departureAirport?.icao }}
                                            </div>
                                            <div class="text-xs">{{ result.legs[0].departureAirport?.name }}</div>
                                        </div>
                                    </div>
                                    <div class="col-span-1 flex place-content-center place-items-center">
                                        <icon name="heroicon-outline-arrow-right" class="h-4 w-4"></icon>
                                    </div>
                                    <div class="col-span-3 md:col-span-1">
                                        <div class="w-full h-full text-center md:text-left p-3">
                                            <div class="text-sm italic">{{ result.legs[0].arrivalAirport?.icao }}</div>
                                            <div class="text-xs">{{ result.legs[0].arrivalAirport?.name }}</div>
                                        </div>
                                    </div>
                                    <div class="col-span-7 md:col-span-1">
                                        <div class="w-full h-full text-center p-3">
                                            {{ result.legs[0].times.takeoffUTC | date:'M/d' }}
                                        </div>
                                    </div>
                                    <div class="col-span-7 md:col-span-1">
                                        <div class="w-full h-full text-center p-3">
                                            {{ (result.estimate?.total || result.price?.total) | currency:'USD':'symbol' }}
                                        </div>
                                    </div>

                                </div>
                                
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
                
            </div>
        </div>

    `
})
export class LaroBookNewFlight {
    @Output() onChange: EventEmitter<QuoteRequest> = new EventEmitter<QuoteRequest>();
    @Output() onSelect: EventEmitter<[QuoteRequest,Client]> = new EventEmitter<[QuoteRequest,Client]>();
    @Input() quote: QuoteRequest;
    clientQuotes: any[] = [];
    today = new Date();
    section: any;
    ETripType = ETripType;
    getScoreColor = getScoreColor;

    constructor(
        public ref: ElementRef
    ) {
    }

    loadResults(results: QuoteRequest[]) {
        this.clientQuotes = results;
    }

    setQuote(item: Deal|QuoteRequest, client: Client) {
        if (item instanceof Deal) {
            let q: QuoteRequest = new QuoteRequest(item);
            q.tripType = ETripType.ONE_WAY;
            q.source = ERequestSource.MarketplaceQuote;
            q.legs = [new TripLeg(item)];
            q.estimate = item.estimate;
            this.onSelect.emit([q,client]);
        } else {
            this.onSelect.emit([item,client]);
        }
    }
}

