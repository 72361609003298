import {Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';

import { QuoteRequest, ETripType, TripLeg, Airport, EPaxType, Contact as LaroContact} from '@nxt/model-laro';
import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {InputSelectComponent} from '@library/shared/input/input-select.component';
import {LegFormComponent} from '../../_components/input/leg-form.component';
import {addDays, format} from 'date-fns';

@Component({
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, InputSelectComponent, LegFormComponent
    ],
    selector: 'laro-marketplace-pooled-quote-form',
    template: `
        <form [formGroup]="form" autocomplete="off">

            <div class="flex space-x-4 flex-wrap">
                <div class="flex whitespace-nowrap m-1">
                    <button (click)="updateLegs(ETripType.ONE_WAY)" class="btn-sm btn-l rounded-l-full {{ quote.tripType===ETripType.ONE_WAY ? 'btn-light':'btn-gray' }}">
                        One-Way
                    </button>
                    <button (click)="updateLegs(ETripType.ROUND_TRIP)" class="btn-sm m-0 rounded-none {{ quote.tripType===ETripType.ROUND_TRIP ? 'btn-light':'btn-gray' }}">
                        Round-Trip
                    </button>
                    <button (click)="updateLegs(ETripType.MULTI_LEG)" class="btn-sm btn-r rounded-r-full {{ quote.tripType===ETripType.MULTI_LEG ? 'btn-light':'btn-gray' }}">
                        Multi-Leg
                    </button>
                </div>
                <div class="m-1 flex whitespace-nowrap">
                    <button class="btn-sm btn-l btn-gray rounded-l-full" [disabled]="true">
                        Passengers
                    </button>
                    <button (click)="incrPax(-1)" class="btn-sm btn-light btn-c">
                        -
                    </button>
                    <button class="btn-sm btn-light btn-c">
                        {{ quote.paxNum }}
                    </button>
                    <button (click)="incrPax(1)" class="btn-sm btn-r btn-light rounded-r-full">
                        +
                    </button>
                </div>
            </div>

            <ng-container *ngFor="let leg of quote.legs; let i = index;">
                <div class="flex place-content-baseline mt-4 mb-2">
                    <div class="pr-2 text-sm text-white whitespace-nowrap"> Leg {{i + 1}} </div>
                    <div class="w-full border-b border-gray-300"></div>
                </div>
                
                <leg-form 
                        [quote]="quote" [index]="i"
                        [includeTime]="true"
                        [includeDate]="true"
                        [requireDate]="false"
                ></leg-form>
                
            </ng-container>

            <div class="flex mt-4" *ngIf="quote.tripType === ETripType.MULTI_LEG">
                <button class="btn-light btn-sm mr-4" (click)="addLeg()">Add Leg</button>
                <button class="btn-light btn-sm" (click)="removeLeg()">Remove Leg</button>
            </div>
        </form>

        <div class="w-full flex content-center max-w-500 m-auto p-4">
            <button class="flex-none btn-gray" (click)="clear();">
                <span class="w-full tex-center">Clear</span>
            </button>
            <button class="grow btn-accent" (click)="getQuote()">
                <span class="w-full tex-center">Get Quote</span>
            </button>
        </div>
    `
})
export class LaroMarketplacePooledQuoteForm implements OnChanges {
    @ViewChildren(LegFormComponent) legForms: QueryList<LegFormComponent>;
    @Output() onResults: EventEmitter<QuoteRequest[]> = new EventEmitter<QuoteRequest[]>();
    @Input() quote: QuoteRequest;
    loaded: boolean[] = [];
    form: UntypedFormGroup;

    ETripType = ETripType;

    constructor(
        private fb: UntypedFormBuilder,
        private pSvc: PageService,
        private cSvc: ClientService
    ) {

    }

    clear() {
        this.quote = new QuoteRequest();
        this.ngOnChanges();
        this.onResults.emit([]);
    }

    ngOnChanges(changes?: SimpleChanges) {
        if (this.quote) {
            this.quote.tripType = this.quote.tripType || ETripType.ROUND_TRIP;
            this.form = this.fb.group({
                paxNum: [this.quote.paxNum ? this.quote.paxNum : 2, [Validators.required]]
            });
            this.buildLegs();
        }
    }

    incrPax(n: number) {
        if (n < 0 && this.quote.paxNum > 1) {
            this.quote.paxNum--;
        } else if (n > 0 && this.quote.paxNum < 10) {
            this.quote.paxNum++;
        }
    }

    buildLegs() {
        let legs: TripLeg[];
        switch (this.quote.tripType) {
            case ETripType.ONE_WAY:
                legs = this.quote.legs?.length ? [this.quote.legs[0]] : [new TripLeg()];
                break;
            case ETripType.ROUND_TRIP:
                if (!this.quote.legs?.length) {
                    legs = [new TripLeg(), new TripLeg()];
                } else if (this.quote.legs?.length === 1) {
                    legs = [this.quote.legs[0], new TripLeg()];
                } else {
                    legs = [this.quote.legs[0], this.quote.legs[1]];
                }
                break;
            case ETripType.MULTI_LEG:
                legs = this.quote.legs?.length ? this.quote.legs : [];
                while (legs.length < 3) {
                    legs.push(new TripLeg());
                }
                break;
        }

        this.quote.legs = legs.map((l,n) => {
            l.order = n;
            if (this.form.get('paxNum').value) {
                this.quote.paxNum = Number(this.form.get('paxNum').value);
                l.passengers = [];
                while (l.passengers.length < this.quote.paxNum) {
                    l.passengers.push(new LaroContact({pax_type: EPaxType.ADULT}));
                }
            }
            return l;
        });

    }

    updateLegs(type: ETripType) {
        this.quote.tripType = type;
        this.buildLegs();
    }

    addLeg() {
        this.quote.legs.push(new TripLeg());
        if (this.quote.legs.length === 2) {
            this.quote.tripType = ETripType.ROUND_TRIP;
        } else if (this.quote.legs.length > 2) {
            this.quote.tripType = ETripType.MULTI_LEG;
        }
    }

    removeLeg() {
        this.quote.legs.pop();
        if (this.quote.legs.length === 2) {
            this.quote.tripType = ETripType.ROUND_TRIP;
        } else if (this.quote.legs.length === 1) {
            this.quote.tripType = ETripType.MULTI_LEG;
        }
    }

    async getQuote() {
        if (this.form.valid) {

            this.quote.paxNum = Number(this.form.get('paxNum').value);
            this.buildLegs();

            let valid: boolean = true;
            this.legForms.forEach((legForm: LegFormComponent, n: number) => {
                if (!legForm.form.valid) {
                    valid = false;
                    legForm.form.markAllAsTouched();
                }
            });

            if (valid) {
                this.getQuoteAuto();
            }

        } else {
            this.form.markAllAsTouched();
        }
    }

    async getQuoteAuto() {
        try {
            this.pSvc.blocking$.next(true);
            let result: any = await this.cSvc.callAPI(`/laro/quote/estimate/pool`, 'post', { trip: this.quote.toFullJSON() });
            if (result?.length) {
                this.onResults.emit(result);
            }
        } catch (e) {
            this.pSvc.alert$.next(e);
        }
        this.pSvc.blocking$.next(false);
    }


}
