import {EventEmitter, Inject, Injectable, Output} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

import {
    Thread, ThreadMessage, Contact, EMedium, IMessagePost, IMessagePostResult,
    getSID, IMenuItem, Document, ITemplateData, User, Client
} from '@nxt/model-core';

import {FireService, IFirestoreQuery} from './fire.service';
import {UserService} from './user.service';
import {PageService} from '../../shared/_services/page.service';
import {Router} from '@angular/router';
import {ClientService} from '../../shared/_services/client.service';
import {NxtFollowUpDialog} from '../../../nxto/src/app/_components/follow-up/nxt-follow-up.dialog';
import {LocalStorageService} from '../../shared/_services/local-storage.service';

@Injectable()
export class MessagingService {
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    context$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    mode$: BehaviorSubject<'threads' | 'messages'> = new BehaviorSubject<'threads' | 'messages'>(null);
    counts$: BehaviorSubject<any> = new BehaviorSubject<{}>({});
    thread$: BehaviorSubject<Thread> = new BehaviorSubject<Thread>(null);
    msg$: BehaviorSubject<ThreadMessage> = new BehaviorSubject<ThreadMessage>(null);
    templates$: BehaviorSubject<IMenuItem[]> = new BehaviorSubject<IMenuItem[]>(null);
    templateDocument$: BehaviorSubject<Document> = new BehaviorSubject<Document>(null);
    activeMsg$: BehaviorSubject<ThreadMessage> = new BehaviorSubject<ThreadMessage>(undefined);
    onSendEvent: EventEmitter<boolean> = new EventEmitter();

    get loadAll() {
        return this.oSvc.getLoadAll(this.cSvc.name_key);
    }

    get olm() {
        return this.oSvc.getOLM(this.cSvc.name_key);
    }

    constructor(
        private cSvc: ClientService,
        private uSvc: UserService,
        private fSvc: FireService,
        private pSvc: PageService,
        public router: Router,
        private lSvc: LocalStorageService,
        @Inject('ObjectLoaderService') private oSvc: any,
        @Inject('ThreadOptionsService') public tOptSvc: any
    ) {
        combineLatest([this.uSvc.user$, this.cSvc.client$])
            .pipe(
                map(([user, client]) => ({ user, client }))
            )
            .subscribe(async (result) => {
                const { user, client } = result;

                if (user?._exists && client?.id) {
                    this.thread$.next(null);
                } else if (user !== undefined) {
                    this.counts$.next({});
                }

            });

        this.cSvc.c$
            .pipe(distinctUntilChanged((prev, curr) => prev?.name_key === curr?.name_key))
            .subscribe((client:Client) =>{
                if (client?.name_key) {
                    const localState = this.lSvc.localState?.[client.name_key];
                    if (!localState?.conversationMode) {
                        this.lSvc.saveState(client.name_key, { conversationMode: 'messages' });
                    }
                    this.mode$.next(localState?.conversationMode || 'messages');
                }
            });


        // this.context$
        //     .subscribe(
        //         parent => {
        //             if (parent) {
        //                 let t: Thread = this.thread$.getValue();
        //                 if (t?.ref?.path && t.ref.path !== parent?._docRef?.path) {
        //                     this.thread$.next(null);
        //                 }
        //             }
        //         }
        //     );

        // this.thread$
        //     .subscribe(
        //         async t => {
        //             if (t) {
        //                 // Flush so as tomMake sure we don't have the wrong templates loaded
        //                 // this.templateDocument$.next(null);
        //             }
        //         }
        //     );

        // this.pSvc.nav$.subscribe(p => {
        //     this.thread$.next(null);
        //     this.context$.next(null);
        // });

        this.tOptSvc.onChange.subscribe(e => {
            this.onChange.emit();
        });
    }


    async buildThreadOptions(
        thread: Thread,
        context: EThreadContext,
        msg?: ThreadMessage
    ): Promise<IMenuItem[]> {
        return this.tOptSvc.buildThreadOptions(this, thread, context, msg);
    }

    async generatePreview(
        subject: string,
        message: string,
        signature: boolean,
        msg: ThreadMessage,
        thread: Thread,
        doImport?: boolean,
        data?: any
    ) {

        return this.cSvc.callAPI(
            `/cms/documents/templates/${this.templateDocument$.getValue().template_id}`,
            'post',
            {
                parentPath: thread.object?._docRef?.path,
                type: thread.object?._type,
                id: thread.object?.id,
                subject: this.templateDocument$.getValue().subject || subject || '',
                message: message,
                signature: signature,
                msgPath: msg._docRef?.path,
                threadId: thread?.id || '',
                medium: msg.medium || EMedium.EMAIL,
                import: doImport,
                data: data || msg.data,
                metadata: this.templateDocument$.getValue().metadata
            }
        );
    }

    async startMessage(
        parent: any,
        thread?: Thread,
        recipients?: any[],
        cc?: any[],
        medium?: any | EMedium,
        template_id?: string,
        lastMsg?: ThreadMessage,
        forward?: boolean,
        templateData?: ITemplateData[],
        followers?: User[],
        document?: any,
        bcc?: any[],
        opts: { blocking?: boolean } = { blocking: true },
    ) {
        thread = thread || this.thread$.getValue();

        if (thread?.id) {
            // Force WEB threads back to EMAIL
            medium = medium || thread.medium;
            if (medium === EMedium.WEB) {
                medium = EMedium.EMAIL;
            }
            let msg: ThreadMessage = this.getMsg(thread.id);
            if (!msg) {
                msg = new ThreadMessage();
                msg.creator = this.uSvc.user$.getValue();
                msg.setID();
                msg.medium = medium;
                msg.data = templateData;
                msg.add('cc', cc || []);
                msg.add('bcc', bcc || []);
                msg.add('recipients', recipients || []);
                msg.forward = forward;
                msg.add('followers', followers);
                msg.remove('followers', msg.agents);
                if (document?.metadata?.sms && document?.sms) {
                    msg.message = document?.sms;
                }

                // Make sure the client.email isn't in the recipient list.
                let x: number = msg.recipients.findIndex(r => r.email === this.cSvc.client$.getValue().email);
                if (x > -1) {
                    msg.recipients.splice(x, 1);
                }
                msg.remove('cc', msg.recipients); // Make sure there are no recipients also in CC.

                if (lastMsg) {
                    if (msg.medium === EMedium.EMAIL) {
                        msg.replyingTo = lastMsg.date;
                    }
                    // Copy attachments if forwarding.
                    if (forward && lastMsg?.files?.length) {
                        msg.files = lastMsg.files.map(file => {
                            delete file._docRef;
                            return file;
                        });
                    }
                }

                msg.template_id = template_id || '';
                if (template_id) {

                    if (this.templates$.getValue()) {
                        let d = document || this.templates$.getValue()?.find((i: IMenuItem) => i.value?.template_id === template_id)?.value;
                        if (opts.blocking) this.pSvc.blocking$.next(true);

                        let result = await this.generatePreview(
                            d?.subject,
                            '',
                            false,
                            msg,
                            thread,
                            false
                        );
                        msg.subject = result?.subject || msg.subject;
                        msg.document_id = d?.id;
                        if (opts.blocking) this.pSvc.blocking$.next(false);


                    }
                } else if (msg.medium === EMedium.EMAIL) {
                    // Get last msg in thread to get a Re: subject.
                    msg.subject = lastMsg?.subject || '';
                    if (!forward && msg.subject && !msg.subject.match(/^Re/i)) {
                        msg.subject = `Re: ${msg.subject}`;
                    } else if (forward) {
                        msg.subject = `Fwd: ${msg.subject}`;
                    }
                }

                this.saveMsg(thread.id, msg);
            }

            this.activeMsg$.next(msg);
        }
    }

    async findThread(parent: any, template_id?: string, medium?: EMedium) {
        let query: IFirestoreQuery[] = [
            {name: 'where', args: ['object.id', '==', parent.id]},
            {name: 'orderBy', args: ['last_date', 'desc']},
            {name: 'limit', args: [1]}
        ];
        if (template_id) {
            query.splice(0, 0, {name: 'where', args: ['template_id', '==', template_id]});
        }
        if (medium) {
            query.splice(0, 0, {name: 'where', args: ['medium', '==', medium]});
        }

        try {
            let q: any = await this.fSvc.getColl(`clients/${this.cSvc.client_id}/threads`, query).toPromise();
            let t: Thread = new Thread(q.length ? q[0] : {id: getSID(25)}, this.olm);
            t.ref = parent._docRef;
            t.object = parent;
            t.template_id = template_id;
            t.medium = medium;
            t.add('agents', parent?.agents);
            if (t._exists) {
                await t.save();
            }
            return t;
        } catch (e) {
            console.warn(e, `clients/${this.cSvc.client_id}/threads`);
        }
    }

    async startThread(parent: any, medium: any | EMedium, recipients?: any[], id?: string): Promise<any> {
        let t: Thread = new Thread();
        t.id = id || getSID(t._l);
        if (!t._docRef && this.cSvc.client$.getValue()?._docRef) {
            t._docRef = this.cSvc.client$.getValue()._docRef.collection('threads').doc(t.id);
        }
        t.medium = medium;
        t.creator = this.uSvc.user$.getValue();
        t.date = Date.now();
        t.ref = parent?._docRef;
        t.object = parent;
        t.contactRef = (parent instanceof Contact) ? parent._docRef : parent.contact?._docRef ? parent.contact._docRef : null;
        t.contact = (parent instanceof Contact) ? parent : parent.contact ? parent.contact : null;
        t.add('agents', parent.agents);
        t.remove('followers', t.agents);
        t.add('recipients', recipients);
        return t;
    }

    async postMessage(msg: IMessagePost): Promise<IMessagePostResult> {
        let result: any;
        // this.pSvc.blocking$.next(true);
        try {
            if (msg.threadId) {

                this.flushMsg(msg.threadId, 'postMessage');
                ['recipients', 'cc', 'bcc', 'files'].forEach(p => {
                    msg[p] = msg[p]?.map(i => i.toMinJSON ? i.toMinJSON(true) : i.toJSON ? i.toJSON() : i);
                });
                msg.template_id = msg.template_id || this.templateDocument$.getValue()?.template_id || '';
                if (this.templateDocument$.getValue()) {
                    msg.document_id = this.templateDocument$.getValue().id;
                }

                if (!msg.ref?.match(/\/groups\//) && !msg.recipients?.length && !msg.cc?.length && !msg.bcc?.length && (msg.medium === EMedium.TEXT || msg.medium === EMedium.EMAIL)) {
                    throw {
                        title: 'No Recipients!',
                        message: 'Cannot send message that has no recipients.'
                    };
                }
                result = await this.cSvc.callAPI('/messaging/post', 'post', msg);
                this.templateDocument$.next(null);

                if(!msg.skip){
                    this.onSendEvent.emit(true);
                }

            }

        } catch (e) {
            this.pSvc.alert$.next(e);
        }
        // this.pSvc.blocking$.next(false);
        return result;
    }

    async buildMsgOptions(msg: ThreadMessage) {
        let result: IMenuItem[] = [];
        if (msg) {

            result.push({
                label: 'Add Follow-Up',
                click: () => {
                    this.pSvc.modal$.next({
                        component: NxtFollowUpDialog,
                        onLoaded: (comp: NxtFollowUpDialog) => {
                            comp.parent = msg;
                            comp.ngOnChanges();
                            comp.addFollower((msg.agents || []).concat([this.uSvc.user$.getValue()]));
                        }
                    });
                }
            });

            if (msg._exists) {
                result.push({
                    label: msg.unread ? 'Mark As Read' : 'Mark Unread',
                    click: async () => {
                        if (msg.unread) {
                            await msg.update({unread: false});
                        } else {
                            await msg.update({unread: true});
                        }
                        console.log(msg._docRef.path, msg.unread);
                    }
                });
            }

            // Allow users to delete their own messages for 5 minutes.
            if ((
                    (msg.medium === EMedium.CHAT || msg.medium === EMedium.NOTE)
                    && msg.creator?.id === this.uSvc.user$.getValue()?.id
                    && (Date.now() - msg.date) < 300000)
                || this.uSvc.isRole(['admin'])
            ) {
                result.push({
                    label: 'Delete Message',
                    click: async () => {
                        this.pSvc.blocking$.next(true);
                        await msg.delete();
                        this.pSvc.hideBlock(400);
                    }
                });
            }

        }
        return result;
    }

    async discard(msg: ThreadMessage) {
        if (msg) {
            if (msg.draft) {
                await msg.delete();
            }
            this.flushMsg(this.thread$.getValue()?.id, 'discard');
            this.templateDocument$.next(null);
            if (!this.thread$.getValue()._exists) {
                this.thread$.next(null);
            }
        }
    }

    async nav(thread: Thread | ThreadMessage, navOnly?: boolean) {
        try {
            await thread?.markRead();
        } catch (e) {
            console.warn(e);
        }

        if (thread?.ref) {
            await this.navByRef(thread.ref);
            if (!navOnly) {
                setTimeout(() => {
                    this.showThread(thread);
                }, 300);
            }
        }
        this.pSvc.loading$.next(false);
    }

    async navByRef(ref: any, type?: string, id?: string) {
        if (ref?.path || (type && id)) {
            let parts: string = (!ref || ref?.path?.split('/')?.length === 2) ? [null, this.cSvc.client_id].concat(ref?.path?.split('/') || []) : ref.path.split('/');
            let client_id: string = parts[1];
            type = type || parts[parts.length - 2];
            id = id || parts[parts.length - 1];
            let client: any = this.cSvc.clients$.getValue().find(c => c.id === client_id);
            let queryParams: any = {};
            if (!client) {

                this.pSvc.alert$.next({
                    title: 'Uh Oh!',
                    message: `We couldn't figure out where to send you. Please contact an admin.`
                });

            } else {
                let routerLink: string;
                switch (type) {
                    case 'clients':
                        routerLink = `/${client.name_key}/threads/inbox/chat`;
                        break;
                    case 'tasks':
                        routerLink = `/${client.name_key}/tasks/details/${id}`;
                        break;
                    case 'contacts':
                        routerLink = `/${client.name_key}/marketing/contacts/${id}`;
                        break;
                    case 'users':
                        routerLink = `/${client.name_key}/company/users/details/${id}`;
                        break;
                    case 'quotes':
                        routerLink = `/${client.name_key}/quotes/${id}/`;
                        break;
                    case 'subquotes':
                        routerLink = `/${client.name_key}/quotes/${parts[3]}/manager`;
                        queryParams.subquoteId = parts[5];
                        break;
                    case 'orders':
                        routerLink = `/${client.name_key}/orders/${id}/`;
                        break;
                    case 'trips':
                        routerLink = `/${client.name_key}/trips/${id}`;
                        break;
                    case 'deployments':
                        routerLink = `/${client.name_key}/cms/deployments/${id}`;
                        break;
                    case 'work_orders':
                        routerLink = `/${client.name_key}/work/${id}`;
                        break;
                    case 'affiliates':
                        routerLink = `/${client.name_key}/marketplace/affiliates/${id}`;
                        break;
                    case 'groups':
                        routerLink = `/${client.name_key}/marketing/groups/${id}`;
                        break;
                    case 'deals':
                        routerLink = `/${client.name_key}/marketplace/empties/${id}`;
                        break;
                    case 'events':
                        routerLink = `/${client.name_key}/data/events/${id}`;
                        break;
                    case 'colleges':
                        routerLink = `/${client.name_key}/data/colleges/${id}`;
                        break;
                    case 'pricing':
                        routerLink = `/${client.name_key}/data/pricing/pricing/${id}`;
                        break;
                    default:
                        console.warn(`Unhandled type (${type}). Cannot navigate.`);
                        break;
                }
                if (routerLink) {
                    await this.router.navigate([routerLink], {queryParams});
                }
            }
        }
    }

    async showThread(t: Thread | ThreadMessage, followers?: User[]) {

        console.log(t?._docRef?.path);
        if (t instanceof ThreadMessage) {
            await t.markRead();
            this.msg$.next(t);
            if (t.tRef?.get) {
                t = new Thread(await t.tRef.get(), this.olm);
            } else {
                console.warn(`No Related Thread Found: ${t._docRef?.path}`, t);
            }
        }
        if (t) {
            // await t.markRead();
            // t._modal = modal;
            t.add('followers', followers);
            this.thread$.next(t);
            try {
                await this.cSvc.callAPI(`/messaging/thread/${t.id}/markRead`, 'post');
            } catch (e) {
                console.warn(e);
            }
        }
    }

    // flushThread(source?: string) {
        // console.log('flushThread',source);
        // this.thread$.next(null);
    // }

    getMsg(id: string): ThreadMessage {
        let msgs: any = JSON.parse(this.lSvc.localState['drafts'] || '{}');
        return msgs[id] ? new ThreadMessage(msgs[id], this.olm) : null;
    }

    saveMsg(threadId: string, msg: ThreadMessage) {
        if (threadId) {
            let msgs: any = JSON.parse(this.lSvc.localState['drafts'] || '{}');
            if (msg) {
                msgs[threadId] = msg.toJSON ? msg.toJSON() : msg;
            } else {
                delete msgs[threadId];
            }
            this.lSvc.saveState('drafts', JSON.stringify(msgs));
        } else {
            throw new Error(`NO ID ON THREAD. CANNOT SAVE DRAFT`);
        }
    }

    flushMsg(threadId: string, debug?: string) {
        // console.log('flush', source);
        if (threadId) {
            this.saveMsg(threadId, null);
        }
        this.activeMsg$.next(null);
    }

    async startTemplateMessage(
        thread_template_id_to_find: string,
        template_id: string,
        medium: EMedium,
        recipients: Contact[] | User[],
        modal: boolean,
        data?: any,
        document?: any) {

        let t: Thread = this.thread$.getValue();
        if (!t) {
            t = await this.findThread(this.context$.getValue(), thread_template_id_to_find, medium || EMedium.EMAIL);
        }

        await this.showThread(t);

        let msg: any = this.getMsg(t.id);
        if (msg) {

            let result = await this.generatePreview(
                '',
                msg.message,
                false,
                msg,
                t,
                false,
                data
            );

            if (msg) {
                msg.subject = result?.subject || '';
                msg.data = data;
                this.activeMsg$.next(new ThreadMessage(msg, this.olm));
            }

        } else {

            try {
                await this.startMessage(
                    this.context$.getValue(),
                    t,
                    recipients,
                    [],
                    t.medium,
                    template_id,
                    null,
                    null,
                    data,
                    null,
                    document
                );
                if (msg) {
                    msg.data = data;
                }
            } catch (e) {
                console.warn(e);
            }

        }

        this.saveMsg(t.id, msg);
    }

    async linkify(text, expand?: boolean) {
        try {
            let result = await this.cSvc.callAPI('/cms/linkify', 'post', {
                text: text.replace(/\n/g, ' <br>'),
                origin: window.location.origin,
                expand: expand || false
            });
            return result;
        } catch (e) {
            return text;
        }
    }

    async deleteUnreads(u?: User, client?: Client, preserve?: string[]) {
        this.pSvc.loading$.next(true);
        await this.cSvc.callAPI('/user/clear_unread', 'post', {
            user_id: u?.id,
            client_id: client?.id || '',
            preserve: preserve
        });
        this.pSvc.notification$.next({
            title: 'Done!',
            message: `Unreads deleted. It may take a few moments for your unread count indicator to update.`
        });
        this.pSvc.loading$.next(false);

    }

    updateMode(newMode: 'threads' | 'messages') {
        this.lSvc.saveState(this.cSvc.name_key, { conversationMode: newMode });
        this.mode$.next(newMode);
    }
}

export enum EThreadContext {
    'INBOX' = 'inbox',
    'THREAD' = 'thread',
    'DETAILS' = 'details',
    'DASHBOARD' = 'dashboard',
    'MODAL' = 'modal',
    'SIDEBAR' = 'sidebar',
    'UNREAD' = 'unread'
}
