import {Component, ViewChild} from '@angular/core';
import {Client} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {
    InputStackedTextComponent
} from "@library/shared/input/input-stacked-text.component";
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {OnDestroyPage} from "@library/shared/_inherited/ondestroy.page";
import {InputSelectComponent} from "@library/shared/input/input-select.component";
import { Contact, ERequestSource, ETripType, QuoteRequest, TripLeg } from '@nxt/model-laro';

import {PageService} from '@library/shared/_services/page.service';
import {PipesModule} from '@library/shared/_pipes/pipes';
import {SimpleSearch} from '@library/nxt/search/simple-search';

import {ClientService} from '@library/shared/_services/client.service';
import {FlightInfo} from './flight-info';
import {PassengerInfo} from './passenger-info';
import {ConfirmationInfo} from './confirmation-info';
import {LaroBookNewFlight} from './laro-consumer-marketplace-book-new-flight';
import {InputAirport} from './input-airport';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {takeUntil} from 'rxjs/operators';
import {LaroInputDateComponent} from './laro-input-date';
import {getIdTokenResult} from '@angular/fire/auth';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {LaroConsumerEmptyLegSearch} from './laro-consumer-empty-leg-search';
import {FlexComponent} from '@library/shared/flex/flex.component';
import {LaroConsumerHeader} from '../_components/header/laro-consumer-header';
import {OldLaroFooterComponent} from '@library/consumer/laro/footer/old-laro-footer.component';
import {BehaviorSubject} from 'rxjs';

@Component({
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, RouterModule,
        InputStackedTextComponent, InputSelectComponent, PipesModule,
        SimpleSearch, FlightInfo, PassengerInfo, ConfirmationInfo,
        LaroBookNewFlight, InputAirport, IconsComponent, LaroInputDateComponent,
        LaroConsumerEmptyLegSearch, FlexComponent, LaroConsumerHeader, OldLaroFooterComponent
    ],
    selector: 'laro-consumer-marketplace',
    template: `
        <laro-consumer-header [section]="header$|async"></laro-consumer-header>
        
        <ng-container *ngIf="loggedIn===false">
            <div class="w-full h-200 flex flex-col items-center justify-center bg-cover h-screen"
                 style="background-image: url('/assets/images/mountains.jpg')">
                <div class="text-white text-3xl Garamond">Please Sign In or Enter Access Code!</div>
                <a routerLink="/login" class="underline text-white text-3xl Garamond">Click Here</a>
            </div>
        </ng-container>
        <ng-container *ngIf="loggedIn">
            <div class="w-full bg-cover bg-no-repeat bg-center"
                 style="background-image: url('/assets/images/marketplace/marketplace-search-bg.jpg')">
                <div class="pb-20">
                    <div class="max-w-6xl m-auto">
                        <div class="flex flex-col justify-start text-center pt-8 sm:pt-12 lg:pt-16 w-full">
                            <h1 class="text-white text-left pl-6">
                            <span class="Garamond block text-2xl md:text-4xl lg:text-6xl mt-4 w-full mb-1">
                                Flight Search
                            </span>
                            </h1>
                        </div>
                        <div [formGroup]="form" *ngIf="form" class="grid grid-cols-12 w-full">
                            <div class="p-1 md:p-2 col-span-12 sm:col-span-4">
                                <input-airport #departureAirport
                                               label="From" 
                                               class="w-full bg-white text-left rounded-2xl text-dark uppercase"
                                               [form]="form"
                                               controlName="departureAirport"
                                ></input-airport>
                            </div>
                            <div class="p-1 md:p-2 col-span-12 sm:col-span-4">
                                <input-airport #arrivalAirport
                                               label="To"
                                               class="w-full bg-white text-left rounded-2xl text-dark uppercase"
                                               [exclude]="departureAirport?.airport"
                                               [form]="form" 
                                               controlName="arrivalAirport"
                                ></input-airport>
                            </div>
                            <div class="flex p-1 md:p-2 col-span-12 sm:col-span-4">
                                <laro-input-date 
                                        #departDate
                                        label="Date" 
                                        class="w-full bg-white text-left rounded-2xl text-dark uppercase"
                                        [minDate]="today"
                                        [form]="form"
                                        [includeDate]="true"
                                        [requireDate]="true"
                                        controlName="departDate"
                                ></laro-input-date>
                                <button (click)="search()" class="btn-clear ml-2 rounded-full">
                                    <div class="bg-[#33ccd6] rounded-full h-10 w-10 flex place-items-center place-content-center">
                                        <icon name="heroicon-outline-arrow-right" class="h-4 w-4 -rotate-12 text-white"></icon>
                                    </div>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <laro-consumer-empty-leg-search
                    #emptyLegSearch
                    [leg]="leg"
                    (onSelect)="setSelected($event, ERequestSource.MarketplaceDeal)"
            ></laro-consumer-empty-leg-search>

            <laro-consumer-marketplace-book-new-flight
                    *ngIf="emptyLegSearch?.results"
                    [quote]="quote"
                    (onSelect)="setSelected($event, ERequestSource.MarketplaceQuote)"
            ></laro-consumer-marketplace-book-new-flight>
            

            <flight-info #flightInfo [quote]="selectedQuote" (onClicked)="showContactForm()"></flight-info>

            <passenger-info #paxInfo
                            [quote]="selectedQuote"
                            [show]="showContact"
                            (onChange)="showConfirmForm($event)"
            ></passenger-info>

            <confirmation-info #confirmInfo
                               [quote]="selectedQuote"
                               [show]="showConfirm"
                               (onConfirm)="submitQuote()"
            ></confirmation-info>
        </ng-container>

        <old-laro-footer [page]="footer$|async"></old-laro-footer>
    `
})
export class LaroConsumerMarketplace extends OnDestroyPage {
    @ViewChild('departureAirport', {read: InputAirport, static: false}) departureAirport: InputAirport;
    @ViewChild('arrivalAirport', {read: InputAirport, static: false}) arrivalAirport: InputAirport;
    @ViewChild('departDate', {read: LaroInputDateComponent, static: false}) departDate: LaroInputDateComponent;
    @ViewChild('flightInfo', { static: false }) flightInfo: FlightInfo;
    @ViewChild('emptyLegSearch', {static: false}) emptyLegSearch: LaroConsumerEmptyLegSearch;
    @ViewChild('paxInfo', {static: false}) paxInfo: PassengerInfo;
    @ViewChild('confirmInfo', {static: false}) confirmInfo: ConfirmationInfo;

    quote: QuoteRequest = new QuoteRequest({ tripType: ETripType.ONE_WAY });
    selectedQuote: QuoteRequest;
    selectedClient: Client;
    selectedContact: Contact;
    leg: TripLeg = new TripLeg();
    form: UntypedFormGroup;
    ETripType = ETripType;
    today = new Date();
    showContact: boolean;
    showConfirm: boolean;
    loggedIn: boolean;
    ERequestSource = ERequestSource;
    header$: BehaviorSubject<any> = new BehaviorSubject(null);
    footer$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        private fb: UntypedFormBuilder,
        public pSvc: PageService,
        private cSvc: ClientService,
        private lSvc: LocalStorageService
    ) {
        super();

        this.pSvc.page$
            .pipe(takeUntil(this.d$))
            .subscribe(page => {
                if (page) {
                    try {
                        this.header$.next(page.header.content.sections[0]);
                        this.footer$.next(page);
                    } catch (e) {
                        console.warn(e);
                    }
                }
            });

        this.cSvc.u$
            .pipe(takeUntil(this.d$))
            .subscribe(
                async u => {
                    if (u !== undefined) {
                        let token: any = (u?.uid) ? await getIdTokenResult(u, true) : null;
                        this.loggedIn = (!!token?.claims?.claims?.access_code && u?.isAnonymous===false);
                        if (this.loggedIn) {
                            if (this.lSvc.globalState['emptySearch']) {
                                this.leg = new TripLeg(this.lSvc.globalState['emptySearch']);
                            }
                            this.form = this.fb.group({
                                departureAirport: [ this.leg.departureAirport || '', [Validators.required]],
                                arrivalAirport: [ this.leg.arrivalAirport || '', [Validators.required] ],
                                departDate: [this.leg?.times?.takeoffUTC||Date.now(), [Validators.required]]
                            });
                        }
                    }
                }
            )
    }

    search() {
        if (this.form.valid) {
            this.leg.departureAirport = this.form.get('departureAirport').value;
            this.leg.arrivalAirport = this.form.get('arrivalAirport').value;
            this.leg.times.takeoffUTC = this.form.get('departDate').value?.valueOf() || this.form.get('departDate').value;
            this.leg.times.takeoffTZ = this.leg.departureAirport?.timezone;
            this.lSvc.saveGState('emptySearch', this.leg.toJSON());
            this.emptyLegSearch.doEmptySearch();
            this.quote.tripType = ETripType.ONE_WAY;
            this.quote.legs = [this.leg];
        }
    }

    setSelected(result: [QuoteRequest,Client], source: ERequestSource) {
        this.selectedQuote = result[0];
        this.selectedClient = result[1];
        setTimeout(() => {
            let top: number = window.scrollY + this.flightInfo.ref.nativeElement.getBoundingClientRect().top;
            window.scrollTo({left: 0, top: top, behavior: 'smooth'});
        }, 200);
    }

    showContactForm() {
        this.showContact = true;
        setTimeout(() => {
            let top: number = window.scrollY + this.paxInfo.ref.nativeElement.getBoundingClientRect().top;
            window.scrollTo({left: 0, top: top, behavior: 'smooth'});
        }, 200);
    }

    showConfirmForm(contact: Contact) {
        this.selectedContact = contact;
        this.selectedQuote.contact = contact;
        this.showConfirm = true;
        setTimeout(() => {
            let top: number = window.scrollY + this.confirmInfo.ref.nativeElement.getBoundingClientRect().top;
            window.scrollTo({left: 0, top: top, behavior: 'smooth'});
        }, 200);
    }

    async submitQuote() {
        this.pSvc.blocking$.next(true);
        try {
            await this.cSvc.callAPI(`/laro/quote/request?client_id=${this.selectedClient.id}`, 'post', {
                quote: this.selectedQuote.toFullJSON ? this.selectedQuote.toFullJSON() : this.selectedQuote,
                contact: this.selectedContact.toJSON()
            });
            this.quote = new QuoteRequest({ tripType: ETripType.ONE_WAY });
            this.selectedQuote = null;
            this.showContact = false;
            this.showConfirm = false;
            this.pSvc.notification$.next({
                title: 'Request Submitted!',
                message: 'Thank you for your submission. One of our partners will contact you soon.'
            });
        } catch (e) {
            this.pSvc.alert$.next(e);
        }
        this.pSvc.blocking$.next(false);
    }


}

