"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPaxType = exports.FlightLeg = exports.Flight = void 0;
const model_core_1 = require("@nxt/model-core");
const Trip_1 = require("./Trip");
const PriceItem_1 = require("./PriceItem");
const index_1 = require("../index");
class Flight extends model_core_1.Base {
    constructor(data, olMap, type, idLen) {
        super(type || 'flights', idLen || 20);
        this.paxNum = null;
        this.token = '';
        this.isEmptyLeg = false;
        this.price = {};
        this.estimate = {};
        this.tripType = Trip_1.ETripType.ROUND_TRIP;
        this.departure_date = null;
        this.aircraft = null;
        this.duration = {};
        this.passenger_ids = [];
        this.name = '';
        this.notes = '';
        this.metadata = {};
        this.legs = [];
        this.services = new model_core_1.ChildArray();
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Flight);
        this.sortLegs();
        this.setDepartureDate();
    }
    sortLegs() {
        var _a;
        (_a = this.legs) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
            var _a, _b;
            let aT = a.order;
            let bT = b.order;
            if (((_a = a.times) === null || _a === void 0 ? void 0 : _a.takeoffUTC) && ((_b = b.times) === null || _b === void 0 ? void 0 : _b.takeoffUTC)) {
                aT = a.times.takeoffUTC;
                bT = b.times.takeoffUTC;
            }
            return (aT < bT) ? -1 : 1;
        });
    }
    firstLegTimes(hide) {
        let r;
        for (let leg of this.legs) {
            if (!hide || (!leg.isEmptyLeg && leg.active)) {
                r = leg.times;
                break;
            }
        }
        return r;
    }
    allTailNumbers(hide) {
        var _a, _b;
        let r = [];
        for (let leg of this.legs) {
            if (!hide || (!leg.isEmptyLeg && leg.active)) {
                if (((_a = leg.aircraft) === null || _a === void 0 ? void 0 : _a.tail_number) && !r.includes((_b = leg.aircraft) === null || _b === void 0 ? void 0 : _b.tail_number)) {
                    r.push(leg.aircraft.tail_number);
                }
            }
        }
        return r.join(', ');
    }
    calcDuration() {
        var _a;
        this.duration = this.duration || {};
        this.duration.flightTime = 0;
        this.duration.blockTime = 0;
        (_a = this.legs) === null || _a === void 0 ? void 0 : _a.forEach(l => {
            ['flightTime', 'blockTime'].forEach(p => {
                var _a, _b;
                if (this.duration[p] !== undefined && ((_a = l.times) === null || _a === void 0 ? void 0 : _a.duration)) {
                    if (l.times.duration[p]) {
                        this.duration[p] += (_b = l.times) === null || _b === void 0 ? void 0 : _b.duration[p];
                    }
                    else {
                        this.duration[p] = undefined;
                    }
                }
            });
        });
    }
    async loadAll(opts, stack, p) {
        opts = opts || { default_client_id: '' };
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
        this.sortLegs();
    }
    setDepartureDate() {
        var _a, _b;
        if (!this.departure_date && ((_a = this.legs) === null || _a === void 0 ? void 0 : _a.length)) {
            try {
                this.departure_date = (_b = this.legs[0].times) === null || _b === void 0 ? void 0 : _b.takeoffUTC;
            }
            catch (e) {
                console.log('departure_date', this.legs);
            }
        }
    }
    calc(p, overrides) {
        var _a, _b, _c, _d;
        if (this[p]) {
            let order = 0;
            // Pre-tax total
            this[p].totalPreTax = (overrides === null || overrides === void 0 ? void 0 : overrides.totalPreTax) ? overrides.totalPreTax : (_a = this[p].items) === null || _a === void 0 ? void 0 : _a.reduce((total, item) => {
                if (item.taxable && !item.tax && item.multiplier && item.quantity) {
                    if (!item.amount) {
                        item.amount = (0, model_core_1.round)((item.type === PriceItem_1.EPriceItemTypes.PERCENT) ? (item.multiplier * (item.quantity / 100)) : (item.multiplier * item.quantity), 2);
                    }
                    item.order = order;
                    order++;
                    total += item.amount || 0;
                }
                return total;
            }, 0);
            this[p].totalTax = (_b = this[p].items) === null || _b === void 0 ? void 0 : _b.reduce((total, item) => {
                if (item.tax && item.multiplier) {
                    if (!item.amount) {
                        if (item.type === PriceItem_1.EPriceItemTypes.PERCENT) {
                            item.quantity = this[p].totalPreTax || 0;
                        }
                        // console.log(item.quantity,item.multiplier);
                        item.amount = (0, model_core_1.round)((item.type === PriceItem_1.EPriceItemTypes.PERCENT) ? (item.multiplier * (item.quantity / 100)) : (item.multiplier * item.quantity), 2);
                    }
                    item.order = order;
                    // console.log(`totalTex ${item.order}`, item.amount)
                    order++;
                    // console.log(`ADD TAX ${item.label}`)
                    total += item.amount || 0;
                }
                return total;
            }, 0);
            this[p].totalPostTax = (_c = this[p].items) === null || _c === void 0 ? void 0 : _c.reduce((total, item) => {
                if (!item.taxable && !item.tax && item.multiplier && item.quantity) {
                    if (!item.amount) {
                        item.amount = (0, model_core_1.round)((item.type === PriceItem_1.EPriceItemTypes.PERCENT) ? (item.multiplier * (item.quantity / 100)) : (item.multiplier * item.quantity), 2);
                    }
                    item.order = order;
                    order++;
                    // console.log(`ADD POST TAX ${item.label}`);
                    total += item.amount || 0;
                }
                return total;
            }, 0);
            (_d = this[p].items) === null || _d === void 0 ? void 0 : _d.sort((a, b) => (a.order > b.order) ? 1 : -1);
            this[p].total = (overrides === null || overrides === void 0 ? void 0 : overrides.total) ? overrides.total : (0, model_core_1.round)((this[p].totalPreTax || 0) + (this[p].totalTax || 0) + (this[p].totalPostTax || 0), 2);
        }
    }
    toFullJSON() {
        let r = super.toFullJSON();
        this.sortLegs();
        return r;
    }
    toJSON() {
        let r = super.toJSON();
        ['price', 'estimate'].forEach(p => {
            if (r[p]) {
                ['preTaxFees', 'postTaxFees', 'items'].forEach(pp => {
                    if (r[p][pp] && r[p][pp].length) {
                        r[p][pp] = (0, model_core_1.toMinJSON)(r[p], pp);
                    }
                });
            }
        });
        return r;
    }
    toString() {
        return this.name;
    }
    async save(parent) {
        this.setDepartureDate();
        if (!this.date) {
            this.date = Date.now();
        }
        if (!this.departure_date) {
            this.departure_date = Date.now();
        }
        return super.save(parent);
    }
}
exports.Flight = Flight;
index_1.olm.flights = (ref, map) => {
    return new Flight(ref, map);
};
class FlightLeg extends model_core_1.Base {
    constructor(data, olMap, type, idLen) {
        super(type || 'legs', idLen || 12);
        this.order = 1;
        this.third_party = null;
        this.aircraft = null;
        this.departureAirport = null;
        this.arrivalAirport = null;
        this.departureFBO = null;
        this.arrivalFBO = null;
        this.times = { takeoffDate: '', takeoffTime: '', landingDate: '', landingTime: '', duration: {} };
        this.passengers = [];
        this.passenger_ids = [];
        this.lead_passenger_id = '';
        this.crew = [];
        this.crew_ids = [];
        this.isEmptyLeg = false;
        this.status = '';
        this.price = {};
        this.estimate = {};
        this.services = new model_core_1.ChildArray();
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, FlightLeg);
        if (this.active !== false) {
            this.active = true;
        }
    }
    async loadAll(opts, stack, p) {
        var _a;
        opts = opts || { default_client_id: '' };
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        let crew = Object.assign([], this.crew);
        await super.loadAll(opts, stack, p);
        if ((_a = this.services) === null || _a === void 0 ? void 0 : _a.length) {
            this.services.sort((a, b) => {
                return (a.type > b.type) ? 1 : -1;
            });
        }
        this.crew = crew;
    }
    toJSON() {
        var _a, _b;
        let r = super.toJSON();
        r.passenger_ids = ((_a = this.passengers) === null || _a === void 0 ? void 0 : _a.reduce((ps, p) => {
            if (p === null || p === void 0 ? void 0 : p.id) {
                ps.push(p.id);
            }
            return ps;
        }, [])) || this.passenger_ids;
        r.crew_ids = ((_b = this.crew) === null || _b === void 0 ? void 0 : _b.reduce((cs, c) => {
            if (c === null || c === void 0 ? void 0 : c.id) {
                cs.push(c.id);
            }
            return cs;
        }, [])) || this.crew_ids;
        return r;
    }
}
exports.FlightLeg = FlightLeg;
var EPaxType;
(function (EPaxType) {
    EPaxType["ADULT"] = "Adult";
    EPaxType["CHILD"] = "Child";
    EPaxType["INFANT"] = "Infant";
    EPaxType["PET"] = "Pet";
})(EPaxType || (exports.EPaxType = EPaxType = {}));
