import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';

import {InputPhoneComponent} from "@library/shared/input/input-phone.component";
import { InputStackedTextComponent } from "@library/shared/input/input-stacked-text.component";
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {OnDestroyPage} from "@library/shared/_inherited/ondestroy.page";
import {RouterLink} from "@angular/router";
import {Contact, QuoteRequest} from '@nxt/model-laro';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {emailValidator} from '@library/shared/input/input-stacked-text.component';
import {ClientService} from '@library/shared/_services/client.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        InputPhoneComponent,
        InputStackedTextComponent,
        ReactiveFormsModule,
        RouterLink,
        IconsComponent
    ],
    selector: 'passenger-info',
    template: `
        <div *ngIf="quote && show" class="bg-[#69a4c1]/60 py-10">
            <div class="flex flex-col place-items-center pt-4 md:pt-8 m-auto mb-6">
                <div class="text-4xl text-white font-bold py-6">Contact Info</div>
                <ol class="flex text-left ml-10">
                    <li class="w-48">
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 shrink-0">
                                <icon name="heroicon-outline-check-circle" class="h-5 w-5 text-gray-800"></icon>
                            </div>
                            <div class="flex w-full bg-white h-0.5"></div>
                        </div>
                        <div class="mt-3 sm:pe-8">
                            <time class="text-sm text-gray-800">
                                Flight
                            </time>
                        </div>
                    </li>
                    <li class="w-48">
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 shrink-0">
                                <icon name="heroicon-outline-minus-circle" class="h-5 w-5 text-gray-800"></icon>
                            </div>
                            <div class="flex w-full bg-white h-0.5"></div>
                        </div>
                        <div class="mt-3 sm:pe-8">
                            <time class="text-sm text-gray-800">
                                Contact
                            </time>
                        </div>
                    </li>
                    <li class="w-20">
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 shrink-0">
                                <icon name="heroicon-outline-minus-circle" class="h-5 w-5 text-white"></icon>
                            </div>
                        </div>
                        <div class="mt-3 sm:pe-8">
                            <time class="text-sm text-gray-800">
                                Confirm
                            </time>
                        </div>
                    </li>
                </ol>
            </div>

            <div class=" max-w-2xl m-auto">
                <div class="grid grid-cols-2 gap-2 w-full" [formGroup]="form">
                    <div class="bg-white text-left mx-3 rounded-2xl p-3">
                        <label for="first_name" class="left-0 uppercase bottom-0 {{ (form?.get('email')?.touched && form.get('email')?.hasError('required')) ? 'text-red-600' : 'text-dark' }}">
                            First Name
                        </label>
                        <input
                                type="text"
                                formControlName="first_name"
                                id="first_name"
                                class="p-3 w-full text-gray-500 rounded-b-2xl border-none"
                        >
                    </div>
                    <div class="bg-white text-left mx-3 rounded-2xl p-3">
                        <label for="last_name" class="uppercase left-0 bottom-0 {{ (form?.get('email')?.touched && form.get('email')?.hasError('required')) ? 'text-red-600' : 'text-dark' }}">
                            Last Name
                        </label>
                        <input
                                type="text"
                                formControlName="last_name"
                                id="last_name"
                                class="p-3 w-full text-gray-500 rounded-b-2xl border-none"
                        >
                    </div>
                    <div class="bg-white text-left mx-3 rounded-2xl p-3">
                        <label for="email" class="uppercase left-0 bottom-0 {{ (form?.get('email')?.touched && form.get('email')?.hasError('required')) ? 'text-red-600' : 'text-dark' }}">
                            Email
                        </label>
                        <input
                                type="email"
                                formControlName="email"
                                id="email"
                                class="p-3 w-full text-gray-500 rounded-b-2xl border-none"
                        >
                    </div>
                    <div class="bg-white text-left mx-3 rounded-2xl p-3">
                        <label for="last_name" class="uppercase left-0 bottom-0 {{ (form?.get('phone')?.touched && form.get('phone')?.hasError('required')) ? 'text-red-600' : 'text-dark' }}">
                            Phone
                        </label>
                        <input
                                type="text"
                                formControlName="phone"
                                id="phone"
                                class="p-3 w-full text-gray-500 rounded-b-2xl border-none"
                        >
                    </div>
                </div>
                <div class="p-3 flex place-items-center">
                    <button class="btn-accent" (click)="setContact()">
                        Next
                    </button>    
                </div>
                                
            </div>
        </div>
    `
})
export class PassengerInfo extends OnDestroyPage implements OnChanges {
    @Output() onChange: EventEmitter<Contact> = new EventEmitter<Contact>();
    @Input() quote: QuoteRequest;
    @Input() show: boolean;
    form: UntypedFormGroup;
    section: any;

    constructor(
        public ref: ElementRef,
        private fb: UntypedFormBuilder,
        private cSvc: ClientService
    ) {
        super();
    }

    // All is commented out until data is actually usable.
    ngOnChanges(changes: SimpleChanges) {

        let u: any = this.cSvc.u$.getValue();
        this.form = this.fb.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: [ u.email || '', [Validators.required, emailValidator]],
            phone: [ u.phoneNumber || '', Validators.required]

        });
        // if (this.user && !this.form) {
        //     this.form = this.fb.group({
        //         first_name: [{value: this.user?.first_name, disabled: !this.editable}, [Validators.required]],
        //         last_name: [{value: this.user?.last_name, disabled: !this.editable}, [Validators.required]],
        //         email: [{
        //             value: this.user?.email,
        //             disabled: !this.editable
        //         }, [Validators.required, emailValidator]],
        //         phone: [{
        //             value: this.user?.phone,
        //             disabled: !this.editable
        //         }, [Validators.required]],
        //         address: [{
        //             value: this.user?.addresses,
        //             disabled: !this.editable
        //         }],
        //         passportId: [{
        //             value: this.user?.metadata.passportId,
        //             disabled: !this.editable
        //         }],
        //         passportIdSecond: [{
        //             value: this.user?.metadata.passportIdSecond,
        //             disabled: !this.editable
        //         }],
        //
        //     });
        //
        //     if (this.showRequiredFields) {
        //         this.form.markAllAsTouched();
        //     }
    }

    async setContact() {
        if (this.form.valid) {
            this.onChange.emit(new Contact( this.form.value ));
        } else {
            this.form.markAllAsTouched();
        }
    }
}
