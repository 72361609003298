import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {PageService} from '@library/shared/_services/page.service';
import {
    FormControl,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup
} from '@angular/forms';
import {format, parse, startOfDay} from 'date-fns';
import {take} from 'rxjs/operators';
import {InputDateCalendar} from '@library/shared/input/input-date-calendar';
import {InputDateTimeCalendar} from '@library/shared/input/input-date-time-calendar';


@Component({
    selector: 'laro-input-date',
    standalone: true,
    imports: [
        CommonModule, IconsComponent, InputDateTimeCalendar,
        FormsModule, ReactiveFormsModule, InputDateCalendar
    ],
    template: `
        <div #parent [formGroup]="localForm" [class]="class ? class : 'w-full bg-white text-left rounded-2xl text-gray-500'">
            <div class="p-1">
                <div class="flex ml-3 left-0 bottom-0">
                    <label [for]="controlName">{{ label }}</label>
                </div>
                <div class="flex relative">
                    <input type="text"
                           [placeholder]="placeholder"
                           (dblclick)="showCalendar()"
                           [formControlName]="controlName"
                           [id]="controlName"
                           class="w-full mt-0 text-gray-500 rounded-b-2xl text-sm border-0 bg-transparent {{inputClass}}"
                    />
                    <button (click)="showCalendar()" class="btn-gray border-0 absolute -right-1 top-0 h-full">
                        <icon class="h-5 w-5" name="heroicon-outline-calendar"></icon>
                    </button>
                </div>
                <p *ngIf="form?.get(controlName).touched && form.get(controlName)?.hasError('required')"
                   class="px-3 pt-1 italic text-xs text-red-500">
                    Required
                </p>
                <p *ngIf="form?.get(controlName).touched && form.get(controlName)?.hasError('min')"
                   class="px-3 pt-1 italic text-xs text-red-500">
                    Invalid
                </p>
            </div>
        </div>
        <div [formGroup]="form">
            <input type="hidden"
                   [placeholder]="placeholder"
                   [formControlName]="controlName"
                   [id]="controlName"
                   class="h-0 w-0 hidden"
            />
        </div>
    `
})
export class LaroInputDateComponent implements OnChanges {
    @Output() onChange: EventEmitter<[Date,string]> = new EventEmitter<[Date,string]>();
    @Input() class: string = '';
    @Input() label: string;
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() minDate: Date;
    @Input() zIndex: string = ''
    @Input() inputClass: string;
    @Input() hours: string[];
    @Input() minutes: string[];
    @Input() includeTime: boolean = false;
    @Input() includeDate: boolean = false;
    @Input() requireDate: boolean = false;
    @Input() requireTime: boolean = false;
    @Input() placeholder: string;
    localForm: UntypedFormGroup;
    @Input() activeFormat: string;
    formats: any = {
        date:  'M/d/yyyy',
        dateTime:  'M/d/yyyy H:mm'
    }

    constructor(
        public pSvc: PageService,
        private fb: UntypedFormBuilder
    ) {
    }

    ngOnChanges(changes?: SimpleChanges) {
        // Use the passed-in placeholder, if any. Otherwise, use the date format required based on the inputs.
        this.activeFormat = this.includeTime ? this.formats.dateTime : this.formats.date;
        this.placeholder = this.placeholder ? this.placeholder : this.activeFormat;

        if (this.requireTime && !this.includeTime) {
            console.warn(`You cannot leave includeTime false and requireTime true.  Time will not be required.`);
        }
        if (this.form && this.controlName && !this.localForm) {
            let data: any = {};
            let d: Date = (this.form.get(this.controlName).value instanceof Date) ? this.form.get(this.controlName).value : !isNaN(this.form.get(this.controlName).value) ? new Date(this.form.get(this.controlName).value) : null;
            if (this.minDate && d && d.valueOf() < this.minDate.valueOf()) {
                d = this.minDate;
            }
            data[this.controlName] = [d ? format(d, this.activeFormat) : '', [this.dateValidator(this.requireTime, this.minDate)]];
            this.localForm = this.fb.group(data);
        }
        // If the minDate changed, we need to reset the value (possibly);
        let d: Date = this.form.get(this.controlName).value;
        if (this.minDate && d && d.valueOf() < this.minDate.valueOf()) {
            d = this.minDate;
            this.setDate(d);
        }
    }

    setDate(d: Date, skipEmit?: boolean) {
        if (d && !isNaN(d.valueOf())) {
            this.form.get(this.controlName).setValue(d);
            this.localForm?.get(this.controlName)?.setValue(d ? format(d, this.activeFormat) : '');
            if (!skipEmit) {
                console.log(d, this.activeFormat);
                this.onChange.emit([d,this.activeFormat]);
            }
        }
    }

    parseDate(value: string):[Date,string] {
        // Parser handles date-only or dateTime formats, based on what is required/optional
        // Start with whether time is included. If so, check against dateTime format.
        let activeFormat: string = this.includeTime ? this.formats.dateTime : this.formats.date;
        let d: Date = parse(value, activeFormat, new Date());
        // If parse fails, maybe the user only input a date, so check date format.
        if (isNaN(d.valueOf()) && !this.requireTime) {
            activeFormat = this.formats.date;
            d = parse(value, this.formats.date, new Date());
        }
        return [d,activeFormat];
    }

    dateValidator(requireTime: boolean, minDate: Date) {
        return (control: FormControl) => {
            if (control.value) {
                let [d,activeFormat] = this.parseDate(control.value);
                if (isNaN(d.valueOf())) {
                    return { invalid: true };
                } else if (this.requireTime && minDate && minDate.valueOf() > d.valueOf()) {
                    return {min: true};
                } else if (!requireTime && startOfDay(minDate).valueOf() > startOfDay(d).valueOf()) {
                    return {min: true};
                } else if (this.form?.get(this.controlName)?.value?.valueOf() !== d.valueOf()) {
                    this.activeFormat = activeFormat;
                    this.setDate(d, true);
                }
            }
            return null;
        }
    }

    show:boolean;
    showCalendar() {
        if (!this.show) {
            this.show = true;

            let component = this.includeTime ? InputDateTimeCalendar : InputDateCalendar;

            this.pSvc.modal$.next({
                component: component,
                onLoaded: (comp) => {
                    comp.minDate = this.minDate;
                    comp.requireTime = this.requireTime;
                    comp.activeFormat = this.activeFormat;

                    let d: Date = this.form.get(this.controlName).value || this.minDate || new Date();
                    comp.date = format(d, this.includeTime ? this.formats.dateTime : this.formats.date);

                    if (this.hours) {
                        comp.hours = this.hours;
                    }
                    if (this.minutes) {
                        comp.minutes = this.minutes;
                    }
                    comp.ngOnChanges();
                    comp.onDestroy.pipe(take(1)).subscribe(d => {
                        this.show = false;
                    })
                    comp.onClose.pipe(take(1)).subscribe((result: [Date,string]) => {
                        if (result?.length) {
                            let d: Date = result[0];
                            this.activeFormat = result[1];
                            this.setDate(d);
                        }
                    });
                }
            })
        }

    }

}
