import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterLink} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {ConsumerScrollableList} from '@library/consumer/list/consumer-scrollable-list';
import {PageService} from '@library/shared/_services/page.service';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {PipesModule} from '@library/shared/_pipes/pipes';

import {User,IMenuItem} from '@nxt/model-core';
import {ConsumerAccountService} from '@library/consumer/_services/consumer.account.service';
import {ClientService} from '@library/shared/_services/client.service';

@Component({
    selector: 'laro-consumer-user-dropdown',
    standalone: true,
    imports: [
        CommonModule, IconsComponent, ConsumerScrollableList,
        PipesModule, RouterLink, IconsComponent
    ],
    template: `
        <div>
            <a *ngIf="u===null || (user && !user?._exists)" routerLink="/login"  class="btn-clear text-white border-white border-1 rounded-full btn-sm">
                User Sign-In
            </a>
            <button *ngIf="user?._exists" (click)="toggleMenu()" class="btn-clear text-white border-white border-1 rounded-full btn-sm">
                Marketplace
            </button>
        </div>

        <div *ngIf="show$|async" class="{{ menuState }} absolute top-16 right-0 w-400 max-w-[100vw] z-10 bg-gray-200 rounded-md shadow-lg ring-1 ring-gray-900/5">
            <icon name="heroicon-solid-chevron-up" class="h-8 w-8 text-gray-200 right-0 -top-1.5 absolute"></icon>
            <div class="pt-1 pr-8 w-full flex place-content-end">
                <button class="btn-clear btn-xs hover:text-white" (click)="hide()">
                    <icon name="heroicon-outline-x" class="h-6 w-6"></icon>
                </button>
            </div>
            
            <div class="p-4 pt-0 w-full">
                <div class="mt-6 flow-root">
                    <div class="-my-2">
                        <a *ngFor="let option of options"
                           [routerLink]="option.routerLink"
                           (click)="hide()"
                           [title]="option.label"
                           class="flex place-items-center gap-x-4 py-2 text-sm font-semibold text-gray-900 hover:text-white"
                        >
                            <icon *ngIf="option.icon?.name" [name]="option.icon?.name" class="h-4 w-4"></icon>
                            <span>{{option.label}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="bg-black p-1 rounded-b-md">
                <button title="Log Out" class="btn-clear btn-sm text-white hover:text-gray-300" (click)="signOut()">
                    <icon name="heroicon-outline-arrow-circle-right" class="h-4 w-4 mr-2"></icon>
                    Log Out 
                </button>
            </div>
        </div>
        
        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <li class="rcrt-list-item flex border-b border-gray-200" (click)="handleClick(item['_url'])">
                <div class="w-full flex justify-between p-1">
                    <div class="flex space-x-3">
                        <div class="flex shrink-0 -space-x-1">
                            <img class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white" [src]="item['_src']||'/assets/empty.png'" alt="">
                            <div *ngIf="item['_unread']" class="bg-red-700 h-3 w-3 text-white text-xxs flex place-content-center rounded-full ml-1">&nbsp;</div>
                        </div>
                        <div class="flex flex-col">
                            <div class="text-sm font-semibold text-gray-900">
                                {{ item['_label'] }}
                            </div>
                            <div class="text-xs text-gray-500">
                                {{ item['_description'] }}
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col text-right text-xxs italic text-gray-500 uppercase whitespace-nowrap">
                        <div>{{ item.date | dateAgo }}</div>
                    </div>
                </div>
            </li>
        </ng-template>
    `
})
export class LaroConsumerUserDropdown extends OnDestroyPage implements OnChanges {
    @Input() label: string;
    @Input() user: User;
    @Input() u: any;

    show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    placement: 'top-right' = 'top-right';
    menuState: string = 'opacity-0 -translate-y-1';
    show: boolean = false;
    opts: any;
    options: IMenuItem[] = [];

    constructor(
        public pSvc: PageService,
        private cSvc: ClientService,
        private aSvc: ConsumerAccountService,
        private router: Router,
        private elRef: ElementRef
    ) {
        super();

        this.pSvc.clickEsc$
            .pipe(takeUntil(this.d$))
            .subscribe(e => {
                if (e && this.show) {
                    this.show = false;
                    this.showHideMenu();
                }
            });

        this.pSvc.click$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (this.show
                        && e?.target
                        && this.elRef?.nativeElement
                        && this.elRef.nativeElement.outerHTML.indexOf(e.target['outerHTML']) === -1
                        && (e.target as HTMLElement).id !== "anchor"
                        && (e.target as HTMLElement).id !== "chevron"
                        && (e.target as HTMLElement).nodeName !== "path"
                        && (e.target as HTMLElement).nodeName !== "svg"
                    ) {
                        this.show = false;
                        this.showHideMenu();
                    }
                }
            );


    }

    ngOnChanges(changes: SimpleChanges) {
        this.buildOptions();
    }

    buildOptions() {
        this.options = [];
        if (this.user?._exists) {

            this.options.push({
                label: 'Launch Marketplace Search',
                routerLink: '/marketplace',
                icon: { name: 'custom-flight-takeoff' }
            });

        }
    }

    toggleMenu() {
        this.show = !this.show;
        this.showHideMenu();
    }

    async handleClick(path: string) {
        if (path) {
            this.hide();
            await this.router.navigateByUrl(path);
        } else {
            this.pSvc.notification$.next({
                title: 'Oops!',
                message: `Couldn't find the related item!`
            });
        }
    }

    hide() {
        this.show = false;
        this.showHideMenu();
    }

    showHideMenu() {
        switch (this.show) {
            case true:
                this.show$.next(true);
                setTimeout(() => {
                    this.menuState = 'opacity-100 translate-y-0 transition ease-out duration-200';
                }, 200);
                break;
            default:
                this.menuState = 'opacity-0 -translate-y-1 transition ease-in duration-150';
                setTimeout(() => {
                    this.show$.next(false);
                }, 200);
                break;
        }
    }

    signOut() {
        this.aSvc.signOut();
        this.user = null;
        this.cSvc.user = null;
        this.show$.next(false);
        this.router.navigateByUrl('/');
    }

}
