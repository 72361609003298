import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {Page} from '@nxt/model-core';
import {IconsComponent} from '../../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, IconsComponent],
    selector: 'old-laro-footer',
    template: `
        <footer class="bg-black text-white">
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-8">

                    <div *ngIf="section?.links?.length">
                        <h3 class="text-lg font-bold text-white tracking-wider uppercase">
                            {{section?.linkHeader}}
                        </h3>
                        <ul class="mt-4 space-y-4">
                            <li *ngFor="let link of section?.links">
                                <a [routerLink]="link.url" class="text-base text-gray-300 hover:text-white">
                                    {{ link.label }}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h3 class="text-lg font-bold text-white tracking-wider uppercase">
                            {{section?.socialHeader}}
                        </h3>
                        <!--                        <form class="mt-8 flex">-->
                        <!--                            <div>-->
                        <!--                                <div class="mt-1 flex shadow-sm">-->
                        <!--                                    <div class="relative flex items-stretch flex-grow focus-within:z-10">-->
<!--                                        <input type="text" name="email" id="email" class="focus:ring-dark-500 focus:border-accent block w-full rounded-none pl-10 sm:text-sm border-gray-300" -->
<!--                                               placeholder="Email address"-->
<!--                                        >-->
<!--                                    </div>-->
                        <!--                                    <button class="-ml-px relative uppercase inline-flex items-center space-x-2 px-4 py-2 text-sm font-medium text-gray-700 bg-accent hover:bg-accent-100 focus:outline-none focus:ring-1 focus:ring-dark-500 focus:border-dark-500">-->
                        <!--                                        Subscribe-->
                        <!--                                    </button>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </form>-->

                        <div class="flex flex-row space-x-6 md:order-2 mt-8" *ngFor="let social of section?.socialLogos">
                            <a [href]="social.link" target="_blank"
                               class="text-dark bg-black p-1 hover:text-gray-300">
                                <span class="sr-only">{{social.label}}</span>
                                <img [src]="social.icon" class="h-12 w-12"/>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="mt-8 border-t border-gray-700 pt-8 md:flex md:flex-col md:items-center md:justify-between">
                    <div class="mt-8 text-gray-400 text-sm md:mt-0">{{section?.disclaimer}}</div>
                    <p class="mt-2 text-base text-gray-400 md:mt-0">
                        {{section?.copyrightText}}
                    </p>
                </div>
            </div>
        </footer>

    `
})
export class OldLaroFooterComponent implements OnChanges {
    @Input() page: Page;
    section: any;

    ngOnChanges(changes: SimpleChanges) {
        if (this.page) {
            try {
                this.section = this.page.footer.content.sections.find(s => s.links?.length);
            } catch (e) {
            }
        }
    }
}
