import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';

import { QuoteRequest } from '@nxt/model-laro';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {PipesModule} from '@library/shared/_pipes/pipes';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IconsComponent,
        PipesModule,
    ],
    selector: 'confirmation-info',
    template: `
        <div *ngIf="quote && show" class="bg-[#69a4c1]/70 py-10">
            <div class="flex flex-col place-items-center pt-4 md:pt-8 m-auto mb-6">
                <div class="text-4xl text-white font-bold py-6">Confirmation</div>
                <ol class="flex text-left ml-10">
                    <li class="w-48">
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 shrink-0">
                                <icon name="heroicon-outline-check-circle" class="h-5 w-5 text-gray-800"></icon>
                            </div>
                            <div class="flex w-full bg-white h-0.5"></div>
                        </div>
                        <div class="mt-3 sm:pe-8">
                            <time class="text-sm text-gray-800">
                                Flight
                            </time>
                        </div>
                    </li>
                    <li class="w-48">
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 shrink-0">
                                <icon name="heroicon-outline-minus-circle" class="h-5 w-5 text-gray-800"></icon>
                            </div>
                            <div class="flex w-full bg-white h-0.5"></div>
                        </div>
                        <div class="mt-3 sm:pe-8">
                            <time class="text-sm text-gray-800">
                                Contact
                            </time>
                        </div>
                    </li>
                    <li class="w-20">
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 shrink-0">
                                <icon name="heroicon-outline-minus-circle" class="h-5 w-5 text-gray-800"></icon>
                            </div>
                        </div>
                        <div class="mt-3 sm:pe-8">
                            <time class="text-sm text-gray-800">
                                Confirm
                            </time>
                        </div>
                    </li>
                </ol>
            </div>

            <div class="max-w-xl m-auto p-6">
                <div class="p-3">
                    <div class="text-white text-left">
                        {{ quote.contact.first_name }} {{ quote.contact.last_name }}
                    </div>
                    <div class="text-white text-left">
                        {{ quote.contact.email }}
                    </div>
                    <div class="text-white text-left">
                        {{ quote.contact.phone | phone }}
                    </div>
                </div>
            </div>
            
            <div class="grid grid-cols-3 p-3 mt-6">
                <div class="p-3">
                    <div class="border-b border-white">
                        <div>
                            <icon name="custom-flight-takeoff" class="h-3 w-3 text-gray-200"></icon>
                        </div>
                        <div class="text-4xl text-gray-800 font-bold p-1">
                            {{ quote.legs[0].departureAirport?.iata }}
                        </div>
                        <div class="p-1 text-white">
                            {{ quote.legs[0].departureAirport?.name }}
                        </div>
                        <div class="p-1 text-white">
                            {{ quote.legs[0].departureAirport?.city?.name }}, {{ quote.legs[0].departureAirport?.city?.state }}
                        </div>
                    </div>
                    <div class="p-3">
                        <div class="text-white text-md flex flex-row">
                            {{ quote.legs[0].times.takeoffDate }}
                        </div>
                    </div>
                </div>
                <div class="p-3">
                    <div class="border-b border-white">
                        <div>
                            <icon name="custom-flight-land" class="h-3 w-3 text-gray-200"></icon>
                        </div>
                        <div class="text-4xl text-gray-800 font-bold p-1">
                            {{ quote.legs[0].arrivalAirport?.iata }}
                        </div>
                        <div class="p-1 text-white">
                            {{ quote.legs[0].arrivalAirport?.name }}
                        </div>
                        <div class="p-1 text-white">
                            {{ quote.legs[0].arrivalAirport?.city?.name }}, {{ quote.legs[0].arrivalAirport?.city?.state }}
                        </div>
                    </div>
                    <div class="p-3" *ngIf="quote.legs?.length > 1">
                        <div class="text-white text-md flex flex-row">
                            {{ quote.legs[1].times.takeoffDate }}
                        </div>
                    </div>
                </div>
                <div class="flex flex-col justify-center">
                    <div class="bg-white rounded-2xl mb-5 ml-5 flex flex-col justify-center p-5">
                        <div class="m-auto ">
                            {{ quote.aircraft.name }}
                        </div>
                        <div class="m-auto">

                        </div>
                        <div class="m-auto">
                            {{ (quote.estimate?.total||quote.price?.total) | currency:'USD':'symbol' }}
                        </div>
                    </div>
                    <div class="flex justify-center w-3/4 self-center">
                        <button class="btn-light" (click)="onConfirm.emit()">Confirm</button>
                    </div>
                </div>
            </div>
            
            
        </div>
    `
})
export class ConfirmationInfo implements OnChanges {
    @Output() onConfirm: EventEmitter<QuoteRequest> = new EventEmitter<QuoteRequest>();
    @Input() quote: QuoteRequest;
    @Input() show: boolean;

    constructor(public ref: ElementRef) {}

    ngOnChanges(changes: SimpleChanges) {

    }
}
