import {Component, Optional, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {SwUpdate} from '@angular/service-worker';

import {ModalDialog} from '@library/shared/modal/modal.dialog';
import {AlertDialog} from '@library/shared/alert/alert.dialog';
import {PageService, IModalOptions} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';

import {RootConsumerAppComponent} from '@library/shared/_inherited/root-consumer-app.component';
import {InputDateCalendar} from '@library/shared/input/input-date-calendar';
import {InputDateTimeCalendar} from '@library/shared/input/input-date-time-calendar';
import {ThreadRightClickMenu} from '../../../nxto/src/app/threads/_components/thread-right-click.menu';
import {RightClickMenu} from '@library/shared/right-click/right-click.menu';

@Component({
    selector: 'app-root',
    template: `
        <notification-dialog></notification-dialog>
        <progress-component [loading$]="pSvc.loading$" [blocking$]="pSvc.blocking$"></progress-component>
        <router-outlet></router-outlet>
        <ng-container #modalContainer></ng-container>
    `
})
export class ConsumerAppComponent extends RootConsumerAppComponent {
    @ViewChild('modalContainer', {static: true, read: ViewContainerRef}) modalContainer: ViewContainerRef;
    client_id: string;
    path: string;

    constructor(
        public pSvc: PageService,
        @Optional() private swUpdate: SwUpdate,
        private sanitizer: DomSanitizer,
        private cSvc: ClientService,
        router: Router
    ) {

        super(pSvc, null, router);
        if (this.swUpdate?.isEnabled) {
            this.swUpdate.versionUpdates.subscribe((u) => {
                if  (u?.type === 'VERSION_READY') {
                    window.location.reload();
                }
            });
        }



        this.pSvc.modal$.subscribe(
            (opts: IModalOptions) => {
                setTimeout(() => {
                    if (opts) {
                        let comp: any = ModalDialog;
                        switch (opts.component) {
                            case InputDateCalendar:
                            case InputDateTimeCalendar:
                                comp = opts.component;
                                break;
                        }
                        let ref: any = this.modalContainer.createComponent<any>(comp);
                        if (ref.instance?.init) {
                            ref.instance.init(opts);
                        }
                        if (!ref.instance.onClose) {
                            console.warn('Component is missing an onClose event.');
                        }
                        ref.instance.onClose?.pipe(take(1))
                            .subscribe(e => {
                                switch (comp) {
                                    case ThreadRightClickMenu:
                                    case RightClickMenu:
                                    case InputDateCalendar:
                                    case InputDateTimeCalendar:
                                        if (this.modalContainer.length) {
                                            this.modalContainer.remove(this.modalContainer.length - 1);
                                        }
                                        break;
                                    default:
                                        this.pSvc.clickEsc$.next(true);
                                        break;
                                }
                            });

                        if (opts?.onLoaded) {
                            try {
                                switch (opts.component) {
                                    case InputDateCalendar:
                                    case InputDateTimeCalendar:
                                        opts.onLoaded(ref.instance);
                                        break;
                                }
                            } catch (e) {
                                console.warn(e);
                            }
                        }

                    }
                });
            }
        );

        this.pSvc.alert$
            .subscribe(
                (e: any) => {
                    if (e) {
                        let alert: any = {};
                        if (e.title || e.buttons || e.options || e.error?.title) {
                            alert = e.error?.title ? e.error : e;
                        } else if (e.error) {
                            let alert: any = {};
                            ['title', 'message', 'body', 'details'].forEach(p => {
                                alert[p] = (e.error[p] instanceof String && e.error[p].toString() !== 'undefined') ? e.error[p].toString() : '';
                            });
                            ['body', 'details'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.message += ` ${alert[prop]}`;
                                }
                            });
                            ['name'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.title += ` ${alert[prop]}`;
                                }
                            });
                            // console.warn('AFTER', alert);
                            alert = alert;
                            alert.title = alert.title || 'An Error Occurred';
                            alert.message = alert.message || 'Please try again. If the problem persists please contact support.';
                        }
                        if (!alert.title && e.statusText) {
                            alert.title = e.statusText;
                            alert.message = e.message || '';
                        }

                        this.pSvc.modal$.next({
                            component: AlertDialog,
                            buttons: alert.buttons,
                            label: alert.title,
                            onLoaded: (comp: AlertDialog) => {
                                comp.alert = alert;
                                if (e.html) {
                                    comp.html = this.sanitizer.bypassSecurityTrustHtml(e.html);
                                }
                                console.warn(e, alert);
                                this.pSvc.loading$.next(false);;
                                this.pSvc.blocking$.next(false);
                            }
                        });
                    }
                }
            );

        this.pSvc.clickEsc$
            .subscribe(
                r => {
                    if (r) {
                        if (this.modalContainer.length) {
                            this.modalContainer.remove(this.modalContainer.length - 1);
                        }
                    }
                }
            );

    }

}
